import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import React, { useEffect } from "react";
import type { ViewFields, WelcomeDialogFields } from "~/analytics/AnalyticSession";
import type { Action, AnalyticsDispatchEvent, ViewEvent, ViewProps } from "~/analytics/Analytics";
import { nameForView } from "~/analytics/Analytics";
export interface WelcomeDialogEvent {
    action: Action;
    selection?: string;
}
export type AnalyticWelcomeDialogDispatcher = AnalyticsDispatchEvent<WelcomeDialogEvent>;
export function useAnalyticWelcomeDialogDispatch(): AnalyticWelcomeDialogDispatcher {
    const session = useAnalyticSession();
    return (name: string, event: WelcomeDialogEvent) => {
        const fields: WelcomeDialogFields = {
            Selection: event.selection,
            "Action Type": event.action,
        };
        session.track(name, fields);
    };
}
export interface WelcomeDialogViewEvent extends ViewEvent {
    cards: string[];
}
export type AnalyticWelcomeDialogViewDispatcher = AnalyticsDispatchEvent<WelcomeDialogViewEvent>;
export interface WelcomeDialogViewFields extends ViewFields {
    "Displayed Cards": string;
}
export function useAnalyticWelcomeDialogViewDispatch(): AnalyticWelcomeDialogViewDispatcher {
    const session = useAnalyticSession();
    return (name: string, event: WelcomeDialogViewEvent) => {
        const fields: WelcomeDialogViewFields = {
            "Resource Type": event.resource,
            "Displayed Cards": event.cards.join(", "),
        };
        session.track(name, fields);
    };
}
export interface WelcomeDialogViewProps extends ViewProps {
    displayedCards: string[];
}
export function AnalyticWelcomeDialogView(props: WelcomeDialogViewProps) {
    const dispatch = useAnalyticWelcomeDialogViewDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(nameForView(props.resource), { resource: props.resource, cards: props.displayedCards }), []);
    return <></>;
}
