import type { FeatureToggle } from "@octopusdeploy/octopus-server-client";
import { session } from "~/clientInstance";
// Exposes the feature toggles to consumers
export function isFeatureToggleEnabled(featureToggle: FeatureToggle): boolean {
    if (!session.featureToggles) {
        throw "Feature toggles have not yet loaded. These settings are only available once logged in.";
    }
    return session.featureToggles.includes(featureToggle) ?? false;
}
export function isOptionalFeatureToggleEnabled(featureToggle: FeatureToggle): boolean | "session-not-established" {
    if (!session.featureToggles) {
        return "session-not-established";
    }
    return session.featureToggles.includes(featureToggle) ?? false;
}
