import type { LegacyRouteResolver } from "@octopusdeploy/portal-routes";
import React, { useContext } from "react";
const LegacyRouteResolverContext = React.createContext<LegacyRouteResolver | undefined>(undefined);
export const LegacyRouteResolverProvider = LegacyRouteResolverContext.Provider;
export function useLegacyRouteResolver() {
    const legacyRouteResolver = useContext(LegacyRouteResolverContext);
    if (!legacyRouteResolver) {
        throw new Error("Context value has not been provided via a LegacyRouteResolverProvider");
    }
    return legacyRouteResolver;
}
