/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ArtifactResource, GitRefResource, IHaveSnapshotGitReference, InterruptionResource, ResourceCollection, TaskDetailsResource, TaskResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { AnalyticViewDispatcher } from "~/analytics/Analytics";
import { useAnalyticViewDispatch } from "~/analytics/Analytics";
import { ProcessSnapshotSection } from "~/areas/projects/components/Releases/ProcessSnapshot/ProcessSnapshot";
import ReleaseChangesDetail from "~/areas/projects/components/Releases/ReleaseChanges/ReleaseChangesDetail";
import QueuedTaskDetails from "~/areas/tasks/components/Task/QueuedTaskDetails";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";
import { SimplePagingList } from "../../../../../components/PagingList";
import { Section } from "../../../../../components/Section/Section";
import type { UniqueActivityElement } from "../../../../../components/TaskLogLines/TaskLogBlock";
import ArtifactLink from "../Artifacts/ArtifactLink";
import { HistoryForTask } from "../HistoryForTask";
import Interruption from "../Interruption/Interruption";
import { goToLog } from "../TaskLog/TaskLogUtil";
import TaskSummaryLine from "../TaskSummary/TaskSummaryLine";
interface TaskSummaryComponentProps {
    task: TaskResource<any>;
    projectId?: string;
    environmentId?: string;
    tenantId?: string;
    artifacts?: ArtifactResource[];
    interruptions?: ResourceCollection<InterruptionResource>;
    additionalSidebar?: React.ReactNode;
    activityElements?: UniqueActivityElement[];
    taskDetails?: TaskDetailsResource;
    doRefresh: Refresh;
    changesMarkdown?: string;
    snapshot: IHaveSnapshotGitReference;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
}
class ArtifactsList extends SimplePagingList<ArtifactResource> {
}
type TaskSummaryProps = TaskSummaryComponentProps & RouteComponentProps<any>;
type TaskSummaryInternalProps = TaskSummaryComponentProps & RouteComponentProps<any> & {
    dispatchView: AnalyticViewDispatcher;
};
const RanOnSection: React.FC<{
    task: TaskResource;
}> = ({ task }) => {
    return task!.ServerNode ? (<div>
            <h4>Ran on</h4>
            {task!.ServerNode}
        </div>) : null;
};
RanOnSection.displayName = "RanOnSection"
const ChangesSection: React.FC<{
    changesMarkdown?: string;
}> = ({ changesMarkdown }) => {
    return changesMarkdown ? (
    // TODO: Mark in onboarding to review moving these into a Deployment Details tab
    <div>
            <h4>Changes</h4>
            <ReleaseChangesDetail changesMarkdown={changesMarkdown} maxLines={10}/>
        </div>) : null;
};
ChangesSection.displayName = "ChangesSection"
class TaskSummaryInternal extends DataBaseComponent<TaskSummaryInternalProps, DataBaseComponentState> {
    componentDidMount() {
        this.props.dispatchView("View Task Summary", { resource: "Task Summary" });
    }
    renderInterruptions = () => {
        if (this.props.task!.IsCompleted || !this.props.interruptions || !this.props.interruptions.Items) {
            return null;
        }
        return this.props.interruptions.Items.filter((interruption) => interruption.IsPending).map((interruption) => (<div key={interruption.Id} style={{ marginBottom: "1rem" }}>
                <Interruption interruption={interruption} takeResponsibility={async () => {
                await this.doBusyTask(async () => repository.Interruptions.takeResponsibility(interruption));
                await this.props.doRefresh();
            }} submitInterruption={async (values: {
                [name: string]: any;
            }) => {
                await this.doBusyTask(async () => repository.Interruptions.submit(interruption, values));
                await this.props.doRefresh();
            }}/>
            </div>));
    };
    render() {
        const { task, taskDetails } = this.props;
        const sidebar = (<div>
                <RanOnSection task={task}/>
                <ProcessSnapshotSection resource={this.props.snapshot}/>
                <ChangesSection changesMarkdown={this.props.changesMarkdown}/>
                <PermissionCheck permission={Permission.ArtifactView} project={this.props.projectId} environment={this.props.environmentId} tenant={this.props.tenantId} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.ArtifactView} permission is required to view artifacts
                        </Callout>}>
                    <div>
                        <h4>Artifacts</h4>
                        <ArtifactsList items={this.props.artifacts!} pageSize={10} onRow={(a) => <ArtifactLink artifact={a}/>} empty={<span>
                                    No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                </span>}/>
                    </div>
                </PermissionCheck>
                <PermissionCheck permission={Permission.EventView} project={this.props.projectId} environment={this.props.environmentId} tenant={this.props.tenantId} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.EventView} permission is required to view the history
                        </Callout>}>
                    <HistoryForTask task={this.props.task} projectId={this.props.projectId} environmentId={this.props.environmentId} tenantId={this.props.tenantId}/>
                </PermissionCheck>
                {this.props.additionalSidebar}
            </div>);
        const showFatalsInline = this.props.activityElements!.length !== 1;
        const taskIsWaitingToStart = !(task.HasBeenPickedUpByProcessor || task.HasPendingInterruptions);
        return (<SidebarLayout sideBar={sidebar} extendContentToEdges={true} flexBasis="25rem" sidebarMaxWidth="25rem">
                <Section>{this.renderInterruptions()}</Section>
                <Section>
                    {this.props.activityElements!.map((e) => (<TaskSummaryLine key={e.uniqueId} element={e} artifacts={this.props.artifacts!} onLogNavigation={(taskLogLineId) => goToLog(this.props, taskLogLineId)} showFatalsInline={showFatalsInline} stepsCorrelationIds={this.props.stepsCorrelationIds} gitRef={this.props.gitRef}/>))}
                    {taskIsWaitingToStart && taskDetails ? <QueuedTaskDetails task={task} taskDetails={taskDetails} doBusyTask={this.doBusyTask}/> : null}
                </Section>
            </SidebarLayout>);
    }
    static displayName = "TaskSummaryInternal";
}
function TaskSummary(props: TaskSummaryProps) {
    const dispatchView = useAnalyticViewDispatch(props.projectId);
    return <TaskSummaryInternal dispatchView={dispatchView} {...props}/>;
}
export default withRouter(TaskSummary);
