/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, Tooltip } from "@octopusdeploy/design-system-components";
import type { ReferenceDataItem, ScopeValues } from "@octopusdeploy/octopus-server-client";
import { take, last, zip, head } from "lodash";
import * as React from "react";
import { DEFAULT_COLOR } from "~/areas/library/components/TagSets/TagListEdit/TagListEdit";
import type { ScopeSpecification } from "~/areas/variables/ReadonlyVariableResource/ReadonlyVariableResource";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import type { ScopeItem } from "~/areas/variables/VariableSorting/sortVariables";
import { compareScopeItems, ScopeType } from "~/areas/variables/VariableSorting/sortVariables";
import { ChannelChip, EnvironmentChip, MachineChip, RoleChip, StepChip, ChipIcon, ProcessChip, MissingChip } from "~/components/Chips";
import { MeasureWidthOutOfFlow } from "~/components/Measure/MeasureOutOfFlow";
import { PopoverWhenFocused } from "~/components/PopoverWhenFocused/PopoverWhenFocused";
import ReadonlyText from "~/components/ReadonlyText/ReadonlyText";
import Tag from "~/components/Tag/Tag";
import WarningIcon from "~/components/WarningIcon";
import type { TagIndex } from "~/components/tenantTagsets";
import Lookup from "../../../components/Lookup";
import type { ProjectContextState, WithOptionalProjectContextInjectedProps } from "../../projects/context";
import { withOptionalProjectContext } from "../../projects/context";
import { createAvailableScopeLookup, checkScopeConsistency, getAllScopeConsistencyRules } from "../ScopeConsistency";
import type { AvailableScopeLookup, ScopeConsistencyFailureResult } from "../ScopeConsistency";
import styles from "./style.module.less";
interface ResizingScopeSummaryProps {
    scope: ScopeSpecification;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    emptyContent?: JSX.Element;
    containerWidth: number;
    inconsistencies: ScopeConsistencyFailureResult[];
    scopeLookup: AvailableScopeLookup;
    onShowMoreClicked(): void;
}
interface ResizingScopeSummaryState {
    widthsForScopes: {
        [scopeId: string]: number | undefined;
    };
    measuredMoreButtonWidth: number | undefined;
}
// Make this appear more like a ternary link, with the additional click-area of a secondary button to improve usability.
const labelProps = {
    fontWeight: "inherit",
    fontSize: "0.8125rem",
};
class ResizingScopeSummary extends React.Component<ResizingScopeSummaryProps & WithOptionalProjectContextInjectedProps, ResizingScopeSummaryState> {
    constructor(props: ResizingScopeSummaryProps & WithOptionalProjectContextInjectedProps) {
        super(props);
        this.state = {
            widthsForScopes: {},
            measuredMoreButtonWidth: undefined,
        };
    }
    shouldComponentUpdate(nextProps: ResizingScopeSummaryProps, nextState: ResizingScopeSummaryState) {
        return (nextProps.containerWidth !== this.props.containerWidth ||
            nextProps.scope !== this.props.scope ||
            nextProps.availableScopes !== this.props.availableScopes ||
            nextProps.tagIndex !== this.props.tagIndex ||
            nextState.measuredMoreButtonWidth !== this.state.measuredMoreButtonWidth ||
            this.shouldComponentUpdateBasedOnScopeWidths(nextProps, nextState));
    }
    shouldComponentUpdateBasedOnScopeWidths(nextProps: ResizingScopeSummaryProps, nextState: ResizingScopeSummaryState) {
        const currentScopeWidths = getScopeWidths(this.props, this.state);
        const nextScopeWidths = getScopeWidths(nextProps, nextState);
        // We want to avoid re-rendering until we have all of the widths for all of the scope items
        return (allScopeItemsHaveWidths(currentScopeWidths) !== allScopeItemsHaveWidths(nextScopeWidths) ||
            // but we should also handle the case where some of the widths have changed
            scopeWidthsHaveChanged());
        function getScopeWidths(props: ResizingScopeSummaryProps, state: ResizingScopeSummaryState): ReadonlyArray<number | undefined> {
            return getScopeItems(props.scope, props.availableScopes)
                .map(getIdForScopeItem)
                .map((id) => state.widthsForScopes[id]);
        }
        function scopeWidthsHaveChanged(): boolean {
            // By this point, we have already asserted that this.props.scope === nextProps.scope,
            // so therefore we can just zip up the widths and know that they will match up
            return zip(currentScopeWidths, nextScopeWidths).some((values) => {
                const [currentWidth, nextWidth] = values;
                // We are only interested if whether a width changes from one number to another number,
                // so ignore undefined here
                return currentWidth !== undefined && nextWidth !== undefined && currentWidth !== nextWidth;
            });
        }
        function allScopeItemsHaveWidths(scopeWidths: ReadonlyArray<number | undefined>) {
            return scopeWidths.every((w) => !!w);
        }
    }
    render() {
        const sortedScopeItems = getSortedScopeItems(this.props.scope, this.props.availableScopes);
        const cumulativeWidths = getCumulativeWidths(sortedScopeItems, this.state.widthsForScopes);
        const availableWidth = this.state.measuredMoreButtonWidth ? this.props.containerWidth - this.state.measuredMoreButtonWidth : this.props.containerWidth;
        const numberOfChipsToDisplay = calculateNumberOfElementsToShow(cumulativeWidths, availableWidth);
        const numberToHide = sortedScopeItems.length - numberOfChipsToDisplay;
        const moreButtonLabel = numberToHide > 0 ? `More...` : "Show";
        return (<VariableCell className={styles.scopeCell}>
                <div className={styles.scopesContainer}>
                    <div className={styles.chipContainer}>{this.renderChips(numberOfChipsToDisplay, sortedScopeItems)}</div>
                    {sortedScopeItems.length === 0 && this.props.emptyContent}
                    {numberToHide !== 0 && (<MeasureWidthOutOfFlow onMeasured={(width) => this.setState({ measuredMoreButtonWidth: width })} key={moreButtonLabel /*Re-measure if the label changes, and therefore the width*/}>
                            <ActionButton type={ActionButtonType.Ternary} tabIndex={-1} label={numberToHide < sortedScopeItems.length ? `More...` : "Show"} labelProps={labelProps} onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    this.props.onShowMoreClicked();
                }} disabled={false}/>
                        </MeasureWidthOutOfFlow>)}
                    {this.props.inconsistencies.length > 0 && (<Tooltip style={{ marginLeft: "0.2em" }} content={head(this.props.inconsistencies)!.message}>
                            <WarningIcon />
                        </Tooltip>)}
                </div>
            </VariableCell>);
    }
    private renderChips = (numberToRender: number, scopeItems: ScopeItem[]) => {
        const allChips = scopeItems.map((s) => {
            return (<MeasureWidthOutOfFlow key={getIdForScopeItem(s)} onMeasured={(width) => this.setState((prev) => {
                    return { widthsForScopes: { ...prev.widthsForScopes, [getIdForScopeItem(s)]: width } };
                })}>
                    {renderScopeItem(s, this.props.availableScopes, this.props.tagIndex, this.props.projectContext?.state)}
                </MeasureWidthOutOfFlow>);
        });
        return take(allChips, numberToRender);
    };
    static displayName = "ResizingScopeSummary";
}
function getCumulativeWidths(scope: ScopeItem[], widthsForScopes: {
    [scopeId: string]: number | undefined;
}): Array<number | null> {
    return scope.reduce((p: Array<number | null>, c, i) => {
        const cumulativeWidth = i === 0 ? 0 : last(p)!;
        const currentId = getIdForScopeItem(c);
        const currentWidth = widthsForScopes[currentId];
        const newCumulativeWidth = currentWidth === undefined ? null : cumulativeWidth + currentWidth;
        return [...p, newCumulativeWidth];
    }, []);
}
function calculateNumberOfElementsToShow(widths: ReadonlyArray<number | null>, availableWidth: number) {
    const cw = widths.reduce((p: number[], c, i) => {
        const cumulativeWidth = i === 0 ? 0 : last(p)!;
        const newWidth = c === null ? cumulativeWidth : c;
        return [...p, newWidth];
    }, []);
    return cw.filter((w) => w < availableWidth).length;
}
interface VariableScopeProps {
    scope: ScopeSpecification;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    emptyContent?: JSX.Element;
    // Give the popover a min height the same as the cell height if don't have many chips.
    // I couldn't work out a a better way of doing this in CSS, but a solution might exist
    // Either way, this isn't too bad since our tables need to have a height specified in code anyway
    minHeight: number;
    isFocused: boolean;
    showClickIndicator: boolean;
    containerWidth: number;
    onFocus(): void;
    onBlur(): void;
    onClick?(): void;
}
class VariableScopeInternal extends React.Component<VariableScopeProps & WithOptionalProjectContextInjectedProps> {
    private readonly onClickOutside: () => void;
    constructor(props: VariableScopeProps & WithOptionalProjectContextInjectedProps) {
        super(props);
        this.onClickOutside = () => {
            if (this.props.onBlur) {
                this.props.onBlur();
            }
        };
    }
    render() {
        const renderedScopeChips = getSortedScopeItems(this.props.scope, this.props.availableScopes).map((s) => renderScopeItem(s, this.props.availableScopes, this.props.tagIndex, this.props.projectContext?.state));
        const lookup = createAvailableScopeLookup(this.props.availableScopes);
        const inconsistencies = checkScopeConsistency(getAllScopeConsistencyRules(), () => lookup, this.props.scope);
        return (<PopoverWhenFocused isFocused={this.props.isFocused} onClickOutside={this.props.isFocused ? this.onClickOutside : undefined} position={{ top: 0, left: 0, right: 0 }}>
                <div className={`${styles.clickContainer} ${this.clickableClassName()}`} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                    {this.props.isFocused ? (<VariableCell style={{ minHeight: this.props.minHeight }}>
                            <div className={styles.allChipsContainer}>
                                {renderedScopeChips}
                                {!!renderedScopeChips.length && (<ActionButton type={ActionButtonType.Ternary} tabIndex={-1} label="Show Summary" labelProps={labelProps} onClick={(e) => {
                        e.stopPropagation();
                        this.props.onBlur();
                    }}/>)}
                                {inconsistencies.length > 0 && (<Tooltip style={{ marginLeft: "0.2em" }} content={head(inconsistencies)!.message}>
                                        <WarningIcon />
                                    </Tooltip>)}
                            </div>
                            {this.props.showClickIndicator && (<div>
                                    <ReadonlyText text={"Click or press enter to define scope"} className={styles.defineScope} monoSpacedFont={true}/>
                                </div>)}
                        </VariableCell>) : (<ResizingScopeSummary {...this.props} inconsistencies={inconsistencies} scopeLookup={lookup} onShowMoreClicked={() => this.props.onFocus()}/>)}
                </div>
            </PopoverWhenFocused>);
    }
    private clickableClassName() {
        return this.props.onClick ? styles.clickable : "";
    }
    static displayName = "VariableScopeInternal";
}
function getIdForScopeItem(scopeItem: ScopeItem) {
    return `${scopeItem.type}-${scopeItem.id}-${scopeItem.name}`;
}
function getScopeItems(scope: ScopeSpecification, availableScopes: ScopeValues): ScopeItem[] {
    return [
        ...(scope.Environment || []).map((id) => createScopeItem(id!, ScopeType.Environment, availableScopes.Environments)),
        ...(scope.Role || []).map((id) => createScopeItem(id!, ScopeType.Role, availableScopes.Roles)),
        ...(scope.Machine || []).map((id) => createScopeItem(id!, ScopeType.Machine, availableScopes.Machines)),
        ...(scope.Action || []).map((id) => createScopeItem(id!, ScopeType.Action, availableScopes.Actions)),
        ...(scope.Channel || []).map((id) => createScopeItem(id!, ScopeType.Channel, availableScopes.Channels)),
        ...(scope.TenantTag || []).map((id) => createScopeItem(id!, ScopeType.TenantTag, availableScopes.TenantTags)),
        ...(scope.ProcessOwner || []).map((id) => createScopeItem(id!, ScopeType.ProcessOwner, availableScopes.Processes)),
    ];
    function createScopeItem(id: string, type: ScopeType, availableItems: ReferenceDataItem[]) {
        return { type, id, name: findName(id, availableItems) };
    }
    function findName(id: string, availableItems: ReferenceDataItem[]) {
        const item = availableItems.find((i) => i.Id === id);
        return item ? item.Name : null;
    }
}
function getSortedScopeItems(scope: ScopeSpecification, availableScopes: ScopeValues): ScopeItem[] {
    return getScopeItems(scope, availableScopes).sort(compareScopeItems);
}
function renderScopeItem(scope: ScopeItem, availableScopes: ScopeValues, tagIndex: TagIndex, projectContextState: ProjectContextState | undefined): React.ReactNode {
    return createChip(scope);
    function createChip(item: ScopeItem) {
        switch (item.type) {
            case ScopeType.Environment:
                return createEnvironmentChip(item.id);
            case ScopeType.Role:
                return createRoleChip(item.id);
            case ScopeType.Machine:
                return createMachineChip(item.id);
            case ScopeType.Action:
                return createStepChip(item.id);
            case ScopeType.Channel:
                return createChannelChip(item.id);
            case ScopeType.TenantTag:
                return createTenantTagChip(item.id);
            case ScopeType.ProcessOwner:
                return createProcessChip(item.id);
            default:
                throw new Error("Cannot render unknown scope type");
        }
    }
    function createProcessChip(processId: string) {
        if (availableScopes.Processes.length === 0) {
            return null;
        }
        const match = availableScopes.Processes.filter((x) => x.Id === processId).pop();
        return (<Lookup lookupCollection={availableScopes.Processes} lookupId={processId} getIdFromElement={(element) => element.Id} render={(item) => <ProcessChip processType={match && match.ProcessType} tabIndex={-1} name={item.Name}/>} renderFallback={<MissingChip lookupId={processId}/>}/>);
    }
    function createEnvironmentChip(environmentId: string) {
        if (availableScopes.Environments.length === 0) {
            return <span key={environmentId}/>;
        }
        return (<Lookup lookupCollection={availableScopes.Environments} lookupId={environmentId} getIdFromElement={(element) => element.Id} render={(item) => <EnvironmentChip tabIndex={-1} environmentName={item.Name}/>} renderFallback={<MissingChip type={ChipIcon.Environment} lookupId={environmentId}/>}/>);
    }
    function createMachineChip(machineId: string) {
        if (availableScopes.Machines.length === 0) {
            return <span key={machineId}/>;
        }
        return (<Lookup lookupCollection={availableScopes.Machines} lookupId={machineId} getIdFromElement={(element) => element.Id} render={(item) => <MachineChip tabIndex={-1} machineName={item.Name}/>} renderFallback={<MissingChip type={ChipIcon.Machine} lookupId={machineId}/>}/>);
    }
    function createChannelChip(channelId: string) {
        const isVersionControlled = projectContextState !== undefined && projectContextState.gitRef !== undefined;
        if (!isVersionControlled && availableScopes.Channels.length === 0) {
            return <span key={channelId}/>;
        }
        return (<Lookup lookupCollection={availableScopes.Channels} lookupId={channelId} getIdFromElement={(element) => element.Id} render={(item) => <ChannelChip tabIndex={-1} channelName={item.Name}/>} renderFallback={<MissingChip type={ChipIcon.Channel} lookupId={channelId}/>}/>);
    }
    function createRoleChip(roleId: string) {
        if (availableScopes.Roles.length === 0) {
            return <RoleChip tabIndex={-1} role={roleId}/>;
        }
        return (<Lookup lookupCollection={availableScopes.Roles} lookupId={roleId} getIdFromElement={(element) => element.Id} render={(item) => <RoleChip tabIndex={-1} role={item.Name}/>} renderFallback={<MissingChip type={ChipIcon.Role} lookupId={roleId}/>}/>);
    }
    function createTenantTagChip(tenantTagId: string) {
        if (availableScopes.TenantTags.length === 0) {
            return <span key={tenantTagId}/>;
        }
        const tag = tagIndex[tenantTagId];
        const color = tag ? tag.Color : DEFAULT_COLOR;
        const description = tag ? tag.Description : "";
        return (<Lookup lookupCollection={availableScopes.TenantTags} lookupId={tenantTagId} getIdFromElement={(element) => element.Id} render={(item) => <Tag tabIndex={-1} description={description} tagName={tag ? tag.Name : item.Name} tagColor={color}/>} renderFallback={<MissingChip type={ChipIcon.Tenant} lookupId={tenantTagId}/>}/>);
    }
    function createStepChip(actionId: string) {
        const isVersionControlled = projectContextState !== undefined && projectContextState.gitRef !== undefined;
        if (!isVersionControlled && availableScopes.Actions.length === 0) {
            return <span key={actionId}/>;
        }
        return (<Lookup lookupCollection={availableScopes.Actions} lookupId={actionId} getIdFromElement={(element) => element.Id} render={(item) => <StepChip tabIndex={-1} stepName={item.Name}/>} renderFallback={<MissingChip type={ChipIcon.Step} lookupId={actionId}/>}/>);
    }
}
interface FocusManagedVariableScopeProps {
    scope: ScopeSpecification;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    emptyContent?: JSX.Element;
    showClickIndicator: boolean;
    minHeight: number;
    containerWidth: number;
}
interface FocusManagedVariableScopeState {
    isFocused: boolean;
}
export class FocusManagedVariableScope extends React.Component<FocusManagedVariableScopeProps, FocusManagedVariableScopeState> {
    constructor(props: FocusManagedVariableScopeProps) {
        super(props);
        this.state = { isFocused: false };
    }
    render() {
        return (<div className={styles.focusManagedWrapper}>
                <VariableScope {...this.props} isFocused={this.state.isFocused} onFocus={() => this.setState({ isFocused: true })} onBlur={() => this.setState({ isFocused: false })}/>
            </div>);
    }
    static displayName = "FocusManagedVariableScope";
}
const VariableScope = withOptionalProjectContext(VariableScopeInternal);
export default VariableScope;
