/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { KubernetesConfigMapProperties, KubernetesDeploymentProperties, KubernetesIngressProperties, KubernetesSecretProperties, KubernetesServiceProperties } from "@octopusdeploy/legacy-action-properties";
import type { DockerFeedResource, FeedResource } from "@octopusdeploy/octopus-server-client";
import { isContainerImageRegistry, PackageAcquisitionLocation, PackageSelectionMode } from "@octopusdeploy/octopus-server-client";
import { dump, load as jsyaml } from "js-yaml";
import * as _ from "lodash";
import { JsonUtils } from "../../../utils/jsonUtils";
import type { KeyValueOption } from "../../EditList/ExtendedKeyValueEditList";
import type { KeyValuePair } from "../../EditList/KeyValueEditList";
import type { ActionEditProps } from "../pluginRegistry";
import type { ScriptPackageProperties, ScriptPackageReference } from "../script/ScriptPackageReferenceDialog";
import type { CombinedVolumeDetails, ContainerDetails, ContainerPackageDetails, NodeAffinityDetails, PersistentVolumeClaimDetails, PodAffinityDetails, TolerationDetails } from "./kubernetesDeployContainersAction";
import { getDeploymentResource } from "./kubernetesDeployContainersAction";
import type { IngressRule, IngressTlsCertificate } from "./kubernetesIngressComponent";
import type { ServicePort } from "./kubernetesServiceComponent";
/**
 * Deployment resources need to add labels to Pod and then select those labels in the deployment.
 * The selection of labels is generated on the server to allow pods to be matched to individual
 * deployments. But for the purpose of exporting the YAML, we use a placeholder label with
 * this value.
 */
const PLACEHOLDER_LABEL_VALUE = "OctopusExport";
/**
 * This is the label key that the exported YAML uses as a selector.
 */
const PLACEHOLDER_LABEL_KEY = "octopusexport";
interface KubernetesToleration {
    key: string;
    operator: string;
    value: string;
    effect: string;
}
interface KubernetesSecretEnvVar {
    name: string;
    valueFrom: {
        secretKeyRef: {
            name: string;
            key: string;
        };
    };
}
interface KubernetesConfigmapEnvVar {
    name: string;
    valueFrom: {
        configMapKeyRef: {
            name: string;
            key: string;
        };
    };
}
interface KubernetesHttpHeaders {
    name: string;
    value: string;
}
interface KubernetesMatchExpression {
    key: string;
    operator: string;
    values: string[];
}
interface KubernetesMatchExpressions {
    matchExpressions: KubernetesMatchExpression[];
}
interface KubernetesPodAffinity {
    topologyKey: string;
    namespaces: string[];
    labelSelector: KubernetesMatchExpressions;
}
interface KubernetesPreferredPodAffinity {
    weight: number;
    podAffinityTerm: KubernetesPodAffinity;
}
interface KubernetesNodeAffinityRequired {
    nodeSelectorTerms: KubernetesMatchExpressions[];
}
interface KubernetesNodeAffinityPreference {
    weight: number;
    preference: KubernetesMatchExpressions;
}
interface KubernetesSysctl {
    name: string;
    value: string;
}
interface KubernetesVolumeItem {
    key: string;
    path: string;
}
interface KubernetesVolume {
    name: string;
    configMap: {
        name: string;
        items: KubernetesVolumeItem[];
    };
    secret: {
        name: string;
        items: KubernetesVolumeItem[];
    };
    hostPath: {
        path: string;
        type: string;
    };
    emptyDir: {
        medium: string;
    };
    persistentVolumeClaim: {
        claimName: string;
    };
}
export function exportConfigMap(props: ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, labels: boolean) {
    const configMap = {
        apiVersion: "v1",
        kind: "ConfigMap",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        data: _.mapValues(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.ConfigMapValues"], {}), (v) => _.toString(v)),
    };
    return convertToYAML(configMap);
}
export function importConfigMap(props: ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);
        if (!isRecord(yaml)) {
            throw new Error("Parsed YAML could not be converted to a Record<string, unknown>");
        }
        if (yaml.kind !== "ConfigMap") {
            return new Error(`The 'kind' field must be 'ConfigMap'`);
        }
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapName"]: _.toString(_.get(yaml, "metadata.name")) });
        // Secrets in YAML are base64 encoded, so we need to decode the values
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapValues"]: JSON.stringify(yaml.data) });
        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(_.assign(_.mapValues(_.get(yaml, "metadata.labels") || {}, (v) => _.toString(v)), _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, (v) => _.toString(v)))),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        }
    }
    catch (e) {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a ConfigMap resource" };
    }
}
export function exportSecret(props: ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, labels: boolean) {
    const secret = {
        apiVersion: "v1",
        kind: "Secret",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.SecretName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        data: _.mapValues(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.SecretValues"], {}), (v: string) => btoa(v)),
    };
    return convertToYAML(secret);
}
export function importSecret(props: ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);
        if (!isRecord(yaml) || yaml.kind !== "Secret") {
            throw new Error("Not the correct type");
        }
        props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretName"]: _.toString(_.get(yaml, "metadata.name")) });
        // Secrets in YAML are base64 encoded, so we need to decode the values
        props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretValues"]: JSON.stringify(_.mapValues(yaml.data, (v: string) => atob(v))) });
        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(_.assign(_.mapValues(_.get(yaml, "metadata.labels") || {}, (v) => _.toString(v)), _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, (v) => _.toString(v)))),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        }
    }
    catch {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Secret resource" };
    }
}
function getServicePort(value: string): {
    name?: string;
    number?: number;
} {
    const portValue = getIntOrString(value);
    let port: {
        number?: number;
        name?: string;
    } = {};
    if (isNaN(Number(portValue))) {
        port = {
            name: String(portValue),
        };
    }
    else {
        port = {
            number: Number(portValue),
        };
    }
    return port;
}
export function exportIngress(props: ActionEditProps<KubernetesIngressProperties>, includeLabels: boolean, includeDefaultRule: boolean) {
    const ingressTls = JsonUtils.tryParseArray<IngressTlsCertificate>(props.properties["Octopus.Action.KubernetesContainers.IngressTlsCertificates"], []);
    const ingress = {
        apiVersion: "networking.k8s.io/v1",
        kind: "Ingress",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.IngressName"],
            ...(includeLabels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
            }),
            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"], []).length !== 0 && {
                annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"], []).map((a: KeyValuePair) => [a.key, a.value])),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        spec: {
            ...(props.properties["Octopus.Action.KubernetesContainers.IngressClassName"] && { ingressClassName: props.properties["Octopus.Action.KubernetesContainers.IngressClassName"] }),
            rules: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressRules"], []).map((r: IngressRule) => ({
                host: r.host,
                http: {
                    paths: (_.get(r, "http.paths") || []).map((p: KeyValueOption) => ({
                        path: p.key,
                        pathType: p.option2 || "ImplementationSpecific",
                        backend: {
                            service: {
                                name: p.option || "placeholder",
                                port: getServicePort(p.value),
                            },
                        },
                    })),
                },
            })),
            ...(includeDefaultRule &&
                (props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"] || props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"]) && {
                defaultBackend: {
                    service: {
                        name: props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"] || "placeholder",
                        port: getServicePort(props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"]),
                    },
                },
            }),
            ...(ingressTls.length !== 0 && {
                tls: ingressTls.map((r) => ({
                    hosts: _.get(r, "hosts"),
                    ...((_.get(r, "certificateVariableName") || _.get(r, "secretName")) && {
                        secretName: _.get(r, "certificateVariableName") ? "octopus-managed-certificate" : _.get(r, "secretName"),
                    }),
                })),
            }),
        },
    };
    return convertToYAML(ingress);
}
function importIngressRulesV1Beta1(yaml: any): string {
    return JSON.stringify(
    // transform each incoming rule into the JSON format used by the UI
    (_.get(yaml, "spec.rules") ?? []).map((r: IngressRule) => ({
        host: r.host,
        http: {
            // paths flatten some nesting when displayed in the UI
            paths: (_.get(r, "http.paths") ?? []).map((p: {
                path: string;
                backend: {
                    servicePort: string | number;
                };
            }) => ({
                key: _.toString(p.path),
                value: _.toString(_.get(p, "backend.servicePort")),
                option: _.toString(_.get(p, "backend.serviceName")),
                option2: "ImplementationSpecific",
            })),
        },
    })));
}
function importIngressRulesV1(yaml: any): string {
    return JSON.stringify(
    // transform each incoming rule into the JSON format used by the UI
    (_.get(yaml, "spec.rules") ?? []).map((r: IngressRule) => ({
        host: r.host,
        http: {
            // paths flatten some nesting when displayed in the UI
            paths: (_.get(r, "http.paths") ?? []).map((p: {
                path: string;
                pathType: string;
                backend: {
                    service: {
                        port: {
                            name: string | undefined;
                            number: number | undefined;
                        };
                    };
                };
            }) => ({
                key: _.toString(p.path),
                value: _.toString(_.get(p, "backend.service.port.number") || _.get(p, "backend.service.port.name")),
                option: _.toString(_.get(p, "backend.service.name")),
                option2: _.toString(p.pathType),
            })),
        },
    })));
}
export function importIngress(props: ActionEditProps<KubernetesIngressProperties>, template: string, includeLabelsAndNamespace: boolean, includeDefaultRule: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);
        if (!isRecord(yaml) || yaml.kind !== "Ingress") {
            throw new Error("Not the correct type");
        }
        props.setProperties({ ["Octopus.Action.KubernetesContainers.IngressName"]: _.toString(_.get(yaml, "metadata.name")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.IngressClassName"]: _.toString(_.get(yaml, "spec.ingressClassName")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.IngressAnnotations"]: JSON.stringify(_.toPairs((_.get(yaml, "metadata.annotations") as {
                [key: string]: string;
            }) || {}).map((a: string[]) => ({
                key: _.toString(a[0]),
                value: _.toString(a[1]),
            }))),
        });
        if (yaml.apiVersion === "networking.k8s.io/v1") {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.IngressRules"]: importIngressRulesV1(yaml),
            });
            if (includeDefaultRule) {
                props.setProperties({
                    ["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]: _.toString(_.get(yaml, "spec.defaultBackend.service.name")),
                });
                props.setProperties({
                    ["Octopus.Action.KubernetesContainers.DefaultRulePort"]: _.toString(_.get(yaml, "spec.defaultBackend.service.port.number") || _.get(yaml, "spec.defaultBackend.service.port.name")),
                });
            }
        }
        else if (yaml.apiVersion === "networking.k8s.io/v1beta1") {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.IngressRules"]: importIngressRulesV1Beta1(yaml),
            });
            if (includeDefaultRule) {
                props.setProperties({
                    ["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]: _.toString(_.get(yaml, "spec.backend.serviceName")),
                });
                props.setProperties({
                    ["Octopus.Action.KubernetesContainers.DefaultRulePort"]: _.toString(_.get(yaml, "spec.backend.servicePort")),
                });
            }
        }
        else {
            throw new Error("Unknown Ingress apiVersion, known versions are 'networking.k8s.io/v1' and 'networking.k8s.io/v1beta1'");
        }
        const existingTLS = JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressTlsCertificates"], []);
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.IngressTlsCertificates"]: JSON.stringify((_.get(yaml, "spec.tls") || []).map((t: any) => ({
                hosts: t.hosts,
                /*
                    We have no idea if the secretName in the input is an existing certificate
                    or something Octopus should create. But to allow the Edit YAML button to be
                    clicked and saved without losing data, we assume that if the host(s) being defined
                    exist already, and those hosts link to a certificate, we discard the secretName
                    and use the existing certificate.
                */
                ...(existingTLS
                    // find matching hosts and a defined certificateVariableName. We expect zero or one result here.
                    .filter((e: any) => _.isEmpty(_.xor(e.hosts, t.hosts)) && e.certificateVariableName)
                    // map to an object with the certificateVariableName property
                    .map((e: any) => ({ certificateVariableName: e.certificateVariableName }))
                    // get the first match
                    .shift() || {
                    // Otherwise assume the input is an existing secret
                    secretName: t.secretName,
                }),
            }))),
        });
        if (includeLabelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(_.assign(_.mapValues(_.get(yaml, "metadata.labels") || {}, (v) => _.toString(v)), _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, (v) => _.toString(v)))),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        }
    }
    catch {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Ingress resource" };
    }
}
export function exportService(props: ActionEditProps<KubernetesServiceProperties>, labels: boolean) {
    const service = {
        apiVersion: "v1",
        kind: "Service",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.ServiceName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
            }),
            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"], []).length !== 0 && {
                annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"], []).map((a: KeyValuePair) => [a.key, a.value])),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        spec: {
            type: props.properties["Octopus.Action.KubernetesContainers.ServiceType"],
            ports: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.ServicePorts"], []).map((p: ServicePort) => ({
                name: p.name,
                port: getIntOrString(p.port),
                ...(p.nodePort && {
                    nodePort: getIntOrString(p.nodePort),
                }),
                ...(p.targetPort && {
                    targetPort: getIntOrString(p.targetPort),
                }),
                ...(p.protocol && {
                    protocol: p.protocol,
                }),
            })),
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"], {})).length !== 0 && {
                selector: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"], {}),
            }),
            ...(!labels && {
                selector: {
                    octopusexport: "OctopusExport",
                },
            }),
        },
    };
    return convertToYAML(service);
}
export function importService(props: ActionEditProps<KubernetesServiceProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);
        if (!isRecord(yaml) || yaml.kind !== "Service") {
            throw new Error("Not the correct type");
        }
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceName"]: _.toString(_.get(yaml, "metadata.name")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceType"]: _.toString(_.get(yaml, "spec.type")) || "ClusterIP" });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceClusterIp"]: _.toString(_.get(yaml, "spec.clusterIP")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"]: JSON.stringify(_.toPairs((_.get(yaml, "metadata.annotations") as {
                [key: string]: string;
            }) || {}).map((a: string[]) => ({
                key: _.toString(a[0]),
                value: _.toString(a[1]),
            }))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.ServicePorts"]: JSON.stringify((_.get(yaml, "spec.ports") || [])
                // The property bag saves all values as strings, so change any port numbers to strings
                .map((p: object) => _.mapValues(p, (v) => _.toString(v)))),
        });
        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(_.assign(_.mapValues(_.get(yaml, "metadata.labels") || {}, (v) => _.toString(v)), _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, (v) => _.toString(v)))),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.SelectorLabels"]: JSON.stringify(_.mapValues(_.get(yaml, "spec.selector") || {}, (v) => _.toString(v))),
            });
        }
    }
    catch (e) {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Service resource" };
    }
}
export function exportDeployment(props: ActionEditProps<KubernetesDeploymentProperties, ScriptPackageProperties>, feeds: FeedResource[]) {
    const deployment = {
        ...(getDeploymentResource(props) === "job" && {
            apiVersion: "batch/v1",
        }),
        ...(getDeploymentResource(props) !== "job" && {
            apiVersion: "apps/v1",
        }),
        // Default to Deployment here, because an unset Octopus.Action.KubernetesContainers.DeploymentResourceType means deployment
        kind: props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"] || "Deployment",
        metadata: {
            // The name is a required field, but set a default if a name hasn't been set yet
            name: props.properties["Octopus.Action.KubernetesContainers.DeploymentName"] || "octopus-deployment",
            ...(_.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
            }),
            ...(_.keys(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"], [])).length !== 0 && {
                annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"], []).map((a: KeyValueOption) => [a.key, _.toString(a.value)])),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        spec: {
            // Use a default label as the selector, as the real selectors used by Octopus are specific to an individual deployment
            ...(getDeploymentResource(props) !== "job" && {
                selector: {
                    matchLabels: {
                        [PLACEHOLDER_LABEL_KEY]: PLACEHOLDER_LABEL_VALUE,
                    },
                },
            }),
            ...(getDeploymentResource(props) !== "job" &&
                getIntOrString(props.properties["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]) && {
                revisionHistoryLimit: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]),
            }),
            ...((getDeploymentResource(props) === "deployment" || getDeploymentResource(props) === "statefulset") && {
                replicas: getIntOrStringWithDefault(props.properties["Octopus.Action.KubernetesContainers.Replicas"], 1),
            }),
            ...(getDeploymentResource(props) === "deployment" &&
                props.properties["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"] && {
                progressDeadlineSeconds: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]),
            }),
            ...(getDeploymentResource(props) === "daemonset" && {
                minReadySeconds: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.MinReadySeconds"]),
            }),
            ...((getDeploymentResource(props) === "daemonset" || getDeploymentResource(props) === "statefulset") && {
                updateStrategy: {
                    type: props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"],
                    ...(getDeploymentResource(props) === "statefulset" &&
                        props.properties["Octopus.Action.KubernetesContainers.Partition"] && {
                        rollingUpdate: {
                            partition: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.Partition"]),
                        },
                    }),
                    ...(getDeploymentResource(props) === "daemonset" &&
                        props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"] && {
                        rollingUpdate: {
                            maxUnavailable: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]),
                        },
                    }),
                },
            }),
            ...(getDeploymentResource(props) === "deployment" && {
                strategy: {
                    // Blue green is not a Kubernetes deployment strategy, so revert back to the default if Blue Green was selected
                    ...(props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] !== "BlueGreen" && {
                        type: props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"],
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" &&
                        (props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"] || props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]) && {
                        rollingUpdate: {
                            maxUnavailable: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]),
                            ...(getDeploymentResource(props) === "deployment" && {
                                maxSurge: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]),
                            }),
                        },
                    }),
                },
            }),
            ...(getDeploymentResource(props) === "statefulset" && {
                ...(props.properties["Octopus.Action.KubernetesContainers.StatefulSetServiceName"] &&
                    props.properties["Octopus.Action.KubernetesContainers.ServiceNameType"] === "External" && {
                    serviceName: props.properties["Octopus.Action.KubernetesContainers.StatefulSetServiceName"],
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.ServiceNameType"] !== "External" && {
                    serviceName: "octopusservice",
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.PodManagementPolicy"] && {
                    podManagementPolicy: props.properties["Octopus.Action.KubernetesContainers.PodManagementPolicy"],
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] && {
                    updateStrategy: {
                        type: props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"],
                        ...(props.properties["Octopus.Action.KubernetesContainers.Partition"] && {
                            rollingUpdate: {
                                partition: props.properties["Octopus.Action.KubernetesContainers.Partition"],
                            },
                        }),
                    },
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"] && {
                    volumeClaimTemplates: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"], []).map((p) => exportPersistentVolumeClaim(p)),
                }),
            }),
            ...(getDeploymentResource(props) === "job" && {
                ...(props.properties["Octopus.Action.KubernetesContainers.Completions"] && {
                    completions: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.Completions"]),
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.Parallelism"] && {
                    parallelism: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.Parallelism"]),
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.BackoffLimit"] && {
                    backoffLimit: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.BackoffLimit"]),
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds"] && {
                    activeDeadlineSeconds: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds"]),
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished"] && {
                    ttlSecondsAfterFinished: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished"]),
                }),
            }),
            template: {
                metadata: {
                    labels: {
                        ...JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
                        [PLACEHOLDER_LABEL_KEY]: PLACEHOLDER_LABEL_VALUE,
                    },
                    ...(_.keys(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAnnotations"], [])).length !== 0 && {
                        annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAnnotations"], []).map((a: KeyValueOption) => [a.key, _.toString(a.value)])),
                    }),
                },
                spec: {
                    ...(props.properties["Octopus.Action.KubernetesContainers.DnsPolicy"] && {
                        dnsPolicy: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.DnsPolicy"]),
                    }),
                    ...((JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"], []).length !== 0 ||
                        props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"] ||
                        props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"]) && {
                        dnsConfig: {
                            ...(props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"] && {
                                nameservers: props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"].split("\n"),
                            }),
                            ...(props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"] && {
                                searches: props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"].split("\n"),
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"], []).length !== 0 && {
                                options: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"], []).map((x: KeyValueOption) => ({ name: x.key, value: x.value })),
                            }),
                        },
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.HostNetwork"] && {
                        hostNetwork: getBoolOrString(props.properties["Octopus.Action.KubernetesContainers.HostNetwork"]),
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"] && {
                        terminationGracePeriodSeconds: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]),
                    }),
                    ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []).length !== 0 && {
                        volumes: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []).map((v: CombinedVolumeDetails) => ({
                            name: v.Name,
                            ...(v.Type === "ConfigMap" && {
                                configMap: {
                                    name: v.ReferenceNameType === "LinkedResource" ? "configmapname" : v.ReferenceName,
                                    ...(v.Items &&
                                        v.Items.length != 0 && {
                                        items: v.Items.map((i) => {
                                            return {
                                                key: i.key,
                                                path: i.value,
                                            };
                                        }),
                                    }),
                                },
                            }),
                            ...(v.Type === "Secret" && {
                                secret: {
                                    secretName: v.ReferenceNameType === "LinkedResource" ? "secretname" : v.ReferenceName,
                                    ...(v.Items &&
                                        v.Items.length != 0 && {
                                        items: v.Items.map((i) => {
                                            return {
                                                key: i.key,
                                                path: i.value,
                                            };
                                        }),
                                    }),
                                },
                            }),
                            ...(v.Type === "EmptyDir" && {
                                emptyDir: {
                                    ...(v.EmptyDirMedium && {
                                        medium: v.EmptyDirMedium,
                                    }),
                                },
                            }),
                            ...(v.Type === "HostPath" && {
                                hostPath: {
                                    path: v.HostPathPath,
                                    type: v.HostPathType,
                                },
                            }),
                            ...(v.Type === "PersistentVolumeClaim" && {
                                persistentVolumeClaim: {
                                    claimName: v.ReferenceName,
                                },
                            }),
                            ...(v.Type === "RawYaml" && loadKubernetesYamlAsObject(v.RawYaml)),
                        })),
                    }),
                    ...(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.HostAliases"], []).length !== 0 && {
                        hostAliases: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.HostAliases"], [])
                            .filter((c: KeyValueOption) => c.key !== "")
                            .map((c: KeyValueOption) => exportHostAlias(c)),
                    }),
                    ...(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.Containers"], []).filter((c: ContainerPackageDetails) => c.InitContainer !== "True").length !== 0 && {
                        containers: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.Containers"], [])
                            .filter((c: ContainerPackageDetails) => c.InitContainer !== "True")
                            .map((c: ContainerPackageDetails) => exportContainer(c, props, feeds)),
                    }),
                    ...(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.Containers"], []).filter((c: ContainerPackageDetails) => c.InitContainer === "True").length !== 0 && {
                        initContainers: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.Containers"], [])
                            .filter((c: ContainerPackageDetails) => c.InitContainer === "True")
                            .map((c: ContainerPackageDetails) => exportContainer(c, props, feeds)),
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.PriorityClassName"] && {
                        priorityClassName: props.properties["Octopus.Action.KubernetesContainers.PriorityClassName"],
                    }),
                    ...((props.properties["Octopus.Action.KubernetesContainers.PodReadinessGates"] || "").trim() && {
                        readinessGates: (props.properties["Octopus.Action.KubernetesContainers.PodReadinessGates"] || "")
                            .split("\n")
                            .filter((r) => r.trim())
                            .map((r) => ({ conditionType: r })),
                    }),
                    ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.Tolerations"], []).length !== 0 && {
                        tolerations: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.Tolerations"], []).map((t: TolerationDetails) => ({
                            key: t.Key,
                            operator: t.Operator,
                            value: t.Value,
                            effect: t.Effect,
                        })),
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.RestartPolicy"] && {
                        restartPolicy: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.RestartPolicy"]),
                    }),
                    ...((props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"] ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"], []).length !== 0 ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).length !== 0 ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]) && {
                        securityContext: {
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] && {
                                fsGroup: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]),
                            }),
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] && {
                                runAsGroup: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]),
                            }),
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"] && {
                                runAsUser: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]),
                            }),
                            ...((props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"] || "").trim() && {
                                supplementalGroups: (props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"] || "")
                                    .split(",")
                                    .filter((id) => id.trim())
                                    .map((id) => getIntOrString(id)),
                            }),
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] && {
                                runAsNonRoot: getBoolOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]),
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).length !== 0 && {
                                sysctls: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).map((s: KeyValueOption) => ({ name: s.key, value: s.value })),
                            }),
                            ...((props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] ||
                                props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] ||
                                props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] ||
                                props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]) && {
                                seLinuxOptions: {
                                    level: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"],
                                    role: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"],
                                    type: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"],
                                    user: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"],
                                },
                            }),
                        },
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.PodServiceAccountName"] && {
                        serviceAccountName: props.properties["Octopus.Action.KubernetesContainers.PodServiceAccountName"],
                    }),
                    ...((JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []).length !== 0 ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []).length !== 0 ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []).length !== 0) && {
                        affinity: {
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []).length !== 0 && {
                                nodeAffinity: {
                                    ...(getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).length !== 0 && {
                                        requiredDuringSchedulingIgnoredDuringExecution: {
                                            nodeSelectorTerms: getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).map((n: NodeAffinityDetails) => ({
                                                matchExpressions: _.concat((n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                    values: m.option.split(","),
                                                })), (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                }))),
                                            })),
                                        },
                                    }),
                                    ...(getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).length !== 0 && {
                                        preferredDuringSchedulingIgnoredDuringExecution: getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).map((n: NodeAffinityDetails) => ({
                                            weight: getIntOrString(n.Weight),
                                            preference: {
                                                matchExpressions: _.concat((n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                    values: m.option.split(","),
                                                })), (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                }))),
                                            },
                                        })),
                                    }),
                                },
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []).length !== 0 && {
                                podAffinity: {
                                    ...(getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).length !== 0 && {
                                        requiredDuringSchedulingIgnoredDuringExecution: getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).map((n: PodAffinityDetails) => ({
                                            labelSelector: {
                                                matchExpressions: _.concat((n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                    values: m.option.split(","),
                                                })), (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                }))),
                                            },
                                            topologyKey: n.TopologyKey,
                                        })),
                                    }),
                                    ...(getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).length !== 0 && {
                                        preferredDuringSchedulingIgnoredDuringExecution: getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).map((n: PodAffinityDetails) => ({
                                            weight: getIntOrString(n.Weight),
                                            podAffinityTerm: {
                                                labelSelector: {
                                                    matchExpressions: _.concat((n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                        values: m.option.split(","),
                                                    })), (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                    }))),
                                                },
                                                topologyKey: n.TopologyKey,
                                            },
                                        })),
                                    }),
                                },
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []).length !== 0 && {
                                podAntiAffinity: {
                                    ...(getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).length !== 0 && {
                                        requiredDuringSchedulingIgnoredDuringExecution: getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).map((n: PodAffinityDetails) => ({
                                            labelSelector: {
                                                matchExpressions: _.concat((n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                    values: m.option.split(","),
                                                })), (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                    key: m.key,
                                                    operator: m.value,
                                                }))),
                                            },
                                            topologyKey: n.TopologyKey,
                                        })) ?? null,
                                    }),
                                    ...(getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).length !== 0 && {
                                        preferredDuringSchedulingIgnoredDuringExecution: getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).map((n: PodAffinityDetails) => ({
                                            weight: getIntOrString(n.Weight),
                                            podAffinityTerm: {
                                                labelSelector: {
                                                    matchExpressions: _.concat((n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                        values: m.option.split(","),
                                                    })), (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                    }))),
                                                },
                                                topologyKey: n.TopologyKey,
                                            },
                                        })) ?? null,
                                    }),
                                },
                            }),
                        },
                    }),
                },
            },
        },
    };
    return convertToYAML(deployment);
}
export function importDeployment(props: ActionEditProps<KubernetesDeploymentProperties, ScriptPackageProperties>, feeds: FeedResource[], template: string) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);
        if (!isRecord(yaml) || ["deployment", "daemonset", "statefulset", "job"].indexOf(yaml.kind?.toLowerCase()) === -1) {
            throw new Error("Not the correct type");
        }
        // The resource we are importing
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.DeploymentResourceType"]: _.toString(yaml.kind),
        });
        // Stateful set properties
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.StatefulSetServiceName"]: _.toString(_.get(yaml, "spec.serviceName")),
            ["Octopus.Action.KubernetesContainers.ServiceNameType"]: "External",
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodManagementPolicy"]: _.toString(_.get(yaml, "spec.podManagementPolicy")),
        });
        /*
            The deployment style can be sourced from different places depending on the resource, so only set the value if it defined.
            Otherwise we may clear our a previously set value.
         */
        if (getDeploymentResource(props) === "statefulset" && _.get(yaml, "spec.updateStrategy.type")) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: _.toString(_.get(yaml, "spec.updateStrategy.type")),
            });
        }
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.Partition"]: _.toString(_.get(yaml, "spec.updateStrategy.rollingUpdate.partition")),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"]: JSON.stringify((_.get(yaml, "spec.volumeClaimTemplates") || []).map((p: object) => importPersistentVolumeClaim(p))),
        });
        // Daemonset properties
        if (getDeploymentResource(props) === "daemonset") {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.MinReadySeconds"]: _.toString(_.get(yaml, "spec.minReadySeconds")),
            });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: _.toString(_.get(yaml, "spec.updateStrategy.type")),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxUnavailable"]: _.toString(_.get(yaml, "spec.updateStrategy.rollingUpdate.maxUnavailable")) });
        }
        // Job properties
        if (getDeploymentResource(props) === "job") {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.Completions"]: _.toString(_.get(yaml, "spec.completions")),
            });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.Parallelism"]: _.toString(_.get(yaml, "spec.parallelism")),
            });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.BackoffLimit"]: _.toString(_.get(yaml, "spec.backoffLimit")),
            });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds"]: _.toString(_.get(yaml, "spec.activeDeadlineSeconds")),
            });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished"]: _.toString(_.get(yaml, "spec.ttlSecondsAfterFinished")),
            });
        }
        // Common properties
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.Tolerations"]: JSON.stringify((_.get(yaml, "spec.template.spec.tolerations") || []).map((t: KubernetesToleration) => ({
                Key: t.key,
                Operator: t.operator,
                Value: t.value,
                Effect: t.effect,
            }))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.NodeAffinity"]: JSON.stringify(_.concat(convertKubernetesNodeAffinityRequired(_.get(yaml, "spec.template.spec.affinity.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution") ?? []), convertKubernetesNodeAffinityPreference(_.get(yaml, "spec.template.spec.affinity.nodeAffinity.preferredDuringSchedulingIgnoredDuringExecution") ?? []))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodAffinity"]: JSON.stringify(_.concat(convertKubernetesPreferredPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAffinity.preferredDuringSchedulingIgnoredDuringExecution") ?? []), convertKubernetesPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution") ?? []))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodAntiAffinity"]: JSON.stringify(_.concat(convertKubernetesPreferredPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution") ?? []), convertKubernetesPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution") ?? []))),
        });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PriorityClassName"]: _.toString(_.get(yaml, "spec.template.spec.priorityClassName")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.RestartPolicy"]: _.toString(_.get(yaml, "spec.template.spec.restartPolicy")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: _.toString(_.get(yaml, "metadata.name")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]: _.toString(_.get(yaml, "spec.revisionHistoryLimit")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: _.toString(_.get(yaml, "spec.replicas") ?? "1") });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]: _.toString(_.get(yaml, "spec.progressDeadlineSeconds")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]: _.toString(_.get(yaml, "spec.template.spec.terminationGracePeriodSeconds")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsPolicy"]: _.toString(_.get(yaml, "spec.template.spec.dnsPolicy")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsConfigNameservers"]: _.toString((_.get(yaml, "spec.template.spec.dnsConfig.nameservers") || []).join("\n")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsConfigSearches"]: _.toString((_.get(yaml, "spec.template.spec.dnsConfig.searches") || []).join("\n")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsConfigOptions"]: JSON.stringify((_.get(yaml, "spec.template.spec.dnsConfig.options") || []).map((x: any) => ({ key: x.name, value: x.value }))) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.HostNetwork"]: getTrueFalseOrString(_.get(yaml, "spec.template.spec.hostNetwork")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodAnnotations"]: JSON.stringify(_.toPairs(_.get(yaml, "spec.template.metadata.annotations") as {
                [key: string]: string;
            }).map((a: string[]) => ({
                key: _.toString(a[0]),
                value: _.toString(a[1]),
            }))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.DeploymentAnnotations"]: JSON.stringify(_.toPairs(_.get(yaml, "metadata.annotations") as {
                [key: string]: string;
            }).map((a: string[]) => ({
                key: _.toString(a[0]),
                value: _.toString(a[1]),
            }))),
        });
        props.setProperties({
            /*
                Merge the deployment and pod template labels. This form applies one set of labels to all resources, and so merging the two means we are likely to get the desired labels.
                Note that we omit any labels with a key matching the placeholder that was added to the exported yaml. This means an export/import cycle doesn't add new labels.
             */
            ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(_.assign(_.omit(_.mapValues(_.get(yaml, "metadata.labels") || {}, (v) => _.toString(v)), [PLACEHOLDER_LABEL_KEY]), _.omit(_.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, (v) => _.toString(v)), [PLACEHOLDER_LABEL_KEY]))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.CombinedVolumes"]: JSON.stringify((_.get(yaml, "spec.template.spec.volumes") ?? []).map((v: KubernetesVolume) => ({
                Name: v.name,
                ...(v.configMap && {
                    Type: "ConfigMap",
                    Items: (_.get(v, "configMap.items") ?? []).map((i: KubernetesVolumeItem) => ({ key: _.toString(i.key), value: _.toString(i.path) })),
                    ReferenceName: _.toString(_.get(v, "configMap.name")),
                }),
                ...(v.secret && {
                    Type: "Secret",
                    Items: (_.get(v, "secret.items") ?? []).map((i: KubernetesVolumeItem) => ({ key: _.toString(i.key), value: _.toString(i.path) })),
                    ReferenceName: _.toString(_.get(v, "secret.secretName")),
                }),
                ...(v.emptyDir && {
                    Type: "EmptyDir",
                    EmptyDirMedium: _.toString(_.get(v, "emptyDir.medium")),
                }),
                ...(v.hostPath && {
                    Type: "HostPath",
                    HostPathPath: _.toString(_.get(v, "hostPath.path")),
                    HostPathType: _.toString(_.get(v, "hostPath.type")),
                }),
                ...(v.persistentVolumeClaim && {
                    Type: "PersistentVolumeClaim",
                    ReferenceName: _.toString(_.get(v, "persistentVolumeClaim.claimName")),
                }),
                ...(v.configMap === undefined &&
                    v.secret === undefined &&
                    v.emptyDir === undefined &&
                    v.hostPath === undefined &&
                    v.persistentVolumeClaim === undefined && {
                    Type: "RawYaml",
                    RawYaml: convertToYAML(v),
                }),
            }))),
        });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.fsGroup")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.runAsGroup")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.runAsUser")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]: _.toString((_.isArray(_.get(yaml, "spec.template.spec.securityContext.supplementalGroups")) ? _.get(yaml, "spec.template.spec.securityContext.supplementalGroups") : []).join(",")),
        });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.runAsNonRoot")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.level")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.role")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.type")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.user")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]: JSON.stringify((_.get(yaml, "spec.template.spec.securityContext.sysctls") || []).map((s: KubernetesSysctl) => ({ key: s.name, value: _.toString(s.value) }))),
        });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodServiceAccountName"]: _.toString(_.get(yaml, "spec.template.spec.serviceAccountName")) });
        if (getDeploymentResource(props) === "deployment") {
            props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxUnavailable"]: _.toString(_.get(yaml, "spec.strategy.rollingUpdate.maxUnavailable")) });
        }
        props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxSurge"]: _.toString(_.get(yaml, "spec.strategy.rollingUpdate.maxSurge")) });
        if (getDeploymentResource(props) === "deployment" && _.get(yaml, "spec.strategy.type")) {
            props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: _.toString(_.get(yaml, "spec.strategy.type")) });
        }
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodReadinessGates"]: (_.isArray(_.get(yaml, "spec.template.spec.readinessGates")) ? _.get(yaml, "spec.template.spec.readinessGates") : [])
                .map((r: any) => r.conditionType)
                .filter((r: string) => r && r.trim())
                .map((r: string) => r.trim())
                .join("\n"),
        });
        props.packages;
        props.setPackages(_.concat(_.get(yaml, "spec.template.spec.containers") || [], _.get(yaml, "spec.template.spec.initContainers") || []).map((container: object) => {
            const feedId = getMatchingFeed(feeds, container).Id;
            /*
                Packages may already be referenced by project settings, for example project versions may be
                bound to the package. In this case we need to keep the ID of the existing package if we can
                by matching the name, image and feed.
             */
            return (props.packages.find((p) => p.Name == _.toString(_.get(container, "name")) && p.PackageId == splitReposSearchTerm(_.toString(_.get(container, "image")))[1] && p.FeedId == feedId) ||
                // If there is no match, we are genuinely creating a new package reference
                createPackage(container, getMatchingFeed(feeds, container).Id));
        }));
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.Containers"]: JSON.stringify(_.concat((_.get(yaml, "spec.template.spec.containers") || []).map((container: object) => importContainer(container, feeds, false)), (_.get(yaml, "spec.template.spec.initContainers") || []).map((container: object) => importContainer(container, feeds, true)))),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.HostAliases"]: JSON.stringify((_.get(yaml, "spec.template.spec.hostAliases") || []).map((ha: any) => ({
                key: ha.ip,
                value: _.toString(ha.hostnames),
            }))),
        });
    }
    catch (e) {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Deployment, StatefulSet or DaemonSet resource" };
    }
}
/**
 * Create a new package reference for an image
 * @param container The container details
 * @param feedsId The feed that the image is sourced from
 */
function createPackage(container: object, feedsId: string) {
    return {
        AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
        Name: _.toString(_.get(container, "name")),
        PackageId: splitReposSearchTerm(_.get(container, "image"))[1],
        FeedId: feedsId,
        Properties: {
            Extract: "False",
            PackageParameterName: "",
            SelectionMode: PackageSelectionMode.Immediate,
        },
        Id: null!,
    };
}
/**
 * Attempt to match an image fully qualified name to a feed. Failing that, return the first docker feed.
 * @param feeds The available feeds
 * @param container The k8s container definition
 */
function getMatchingFeed(feeds: FeedResource[], container: object): FeedResource {
    // The first preference is to try and get the feed that matches the image hostname
    return (feeds.find((f) => isContainerImageRegistry(f.FeedType) && isValidUrl((f as DockerFeedResource).FeedUri) && splitReposSearchTerm(_.get(container, "image"))[0].startsWith(new URL((f as DockerFeedResource).FeedUri).hostname)) ||
        // If no feed exists, default to the first feed
        feeds.find((f) => isContainerImageRegistry(f.FeedType)))!;
}
/**
 * Ensure the URL is valid
 * @param url the URL to parse
 */
function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    }
    catch {
        return false;
    }
}
/**
 * Build the host name prefix required to generate a fully qualified docker image name
 * @param f The feed to generate the prefix from
 */
export function getRegistryHostPrefix(f: FeedResource | undefined) {
    const dockerFeed = f as DockerFeedResource;
    // This is only valid for DockerFeedResources
    if (dockerFeed == null || !isValidUrl(dockerFeed.FeedUri)) {
        return "";
    }
    const uri = new URL(dockerFeed.FeedUri);
    const feedUri = uri.hostname;
    if (uri.port && uri.port !== "443") {
        return feedUri + ":" + uri.port + "/";
    }
    return feedUri + "/";
}
/**
 * @param reposName The name of the docker image
 */
export function splitReposSearchTerm(reposName: string): string[] {
    const variablePattern = /(#{.*?})/g;
    const getNameParts = (name: string) => name.split(variablePattern);
    const splitFeedAndImage = (nameParts: string[]): [
        string[] | null,
        string[]
    ] => {
        const firstSlashIndex = _.findIndex(nameParts, (part) => !variablePattern.test(part) && part.includes("/"));
        if (firstSlashIndex === -1) {
            return [null, nameParts];
        }
        const feedParts = [...nameParts.slice(0, firstSlashIndex), nameParts[firstSlashIndex].split("/")[0]];
        const imageParts = [nameParts[firstSlashIndex].split("/").slice(1).join("/"), ...nameParts.slice(firstSlashIndex + 1)];
        return [feedParts, imageParts];
    };
    const stripTag = (nameParts: string[]): string => {
        const lastColonIndex = _.findLastIndex(nameParts, (part) => !variablePattern.test(part) && part.includes(":"));
        if (lastColonIndex === -1) {
            return nameParts.join("");
        }
        return [...nameParts.slice(0, lastColonIndex), nameParts[lastColonIndex].split(":")[0]].join("");
    };
    const isDefaultFeed = (feedParts: string[]): boolean => {
        if (feedParts.some((part) => variablePattern.test(part))) {
            return false;
        }
        // https://github.com/moby/moby/blob/master/registry/service.go#L121
        const feed = feedParts.join("");
        return !feed.includes(".") && !feed.includes(":") && feed !== "localhost";
    };
    const nameParts = getNameParts(reposName);
    const [feedParts, imageParts] = splitFeedAndImage(nameParts);
    if (feedParts === null || isDefaultFeed(feedParts)) {
        return ["index.docker.io", stripTag(nameParts)];
    }
    return [feedParts.join(""), stripTag(imageParts)];
}
/**
 * Attempt to parse the input as an int, or fall back to a string. If the input is empty, return a default value.
 * This is done to try and build YAML with integer properties where possible, but using strings where variable replacement has been used.
 * @param input The string to parse
 * @param defaultNumber The default value to return if the input is empty
 */
export function getIntOrStringWithDefault(input: string, defaultNumber: number): string | number {
    const valueToTest = _.trim(input) || defaultNumber;
    return isNaN(Number(valueToTest)) ? input : Number(valueToTest);
}
/**
 * Attempt to parse the input as an int, or fall back to a string. This is done to try and build YAML with integer
 * properties where possible, but using strings where variable replacement has been used.
 * @param input The string to parse
 */
export function getIntOrString(input: string): string | number {
    const valueToTest = _.trim(input);
    return !valueToTest || isNaN(Number(valueToTest)) ? input : Number(valueToTest);
}
/**
 * Attempt to parse the input as an boolean, or fall back to a string. This is done to try and build YAML with boolean
 * properties where possible, but using strings where variable replacement has been used.
 * @param input The string to parse
 */
export function getBoolOrString(input: string | boolean): string | boolean {
    if (!input) {
        return false;
    }
    if (_.isBoolean(input)) {
        return input;
    }
    if (input.toLowerCase() === "true") {
        return true;
    }
    if (input.toLowerCase() === "false") {
        return false;
    }
    return input;
}
/**
 * Convert input that might be a boolean or a string to True, False, or the string value
 * @param input
 */
export function getTrueFalseOrString(input: string | boolean): string {
    if (_.isBoolean(input)) {
        return input ? "True" : "False";
    }
    return input;
}
/**
 * Convert the supplied object into a YAML string
 * @param input The object to convert
 */
function convertToYAML(input: object) {
    return dump(input, { skipInvalid: true, flowLevel: -1, sortKeys: false });
}
function convertKubernetesPreferredPodAffinity(input: KubernetesPreferredPodAffinity[]) {
    return (input ?? []).map((a: KubernetesPreferredPodAffinity) => ({
        Type: "Preferred",
        Weight: _.toString(a.weight),
        ...convertKubernetesPreferredPodAffinityItem(a.podAffinityTerm),
    }));
}
function convertKubernetesPodAffinity(input: KubernetesPodAffinity[]) {
    return (input ?? []).map((a: KubernetesPodAffinity) => ({
        Type: "Required",
        ...convertKubernetesRequiredPodAffinityItem(a),
    }));
}
function convertKubernetesPreferredPodAffinityItem(input: KubernetesPodAffinity) {
    return {
        NamespacesList: input.namespaces,
        TopologyKey: input.topologyKey,
        InMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "In" || m.operator === "NotIn")
            .map((m: KubernetesMatchExpression) => ({
            key: m.key,
            value: m.operator,
            option: (m.values ?? []).join(","),
        })),
        ExistMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "Exists" || m.operator === "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
            key: m.key,
            value: m.operator,
        })),
    };
}
function convertKubernetesRequiredPodAffinityItem(input: KubernetesPodAffinity) {
    return {
        NamespacesList: input.namespaces,
        TopologyKey: input.topologyKey,
        InMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "In" || m.operator === "NotIn")
            .map((m: KubernetesMatchExpression) => ({
            key: m.key,
            value: m.operator,
            option: (m.values ?? []).join(","),
        })),
        ExistMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "Exists" || m.operator === "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
            key: m.key,
            value: m.operator,
        })),
    };
}
function convertKubernetesNodeAffinityRequired(input: KubernetesNodeAffinityRequired) {
    return (input.nodeSelectorTerms ?? []).map((n: KubernetesMatchExpressions) => ({
        Type: "Required",
        ...convertKubernetesMatchExpression(n.matchExpressions),
    }));
}
function convertKubernetesNodeAffinityPreference(input: KubernetesNodeAffinityPreference[]) {
    return (input || []).map((a: KubernetesNodeAffinityPreference) => ({
        Type: "Preferred",
        Weight: _.toString(a.weight),
        ...convertKubernetesMatchExpression(a.preference.matchExpressions),
    }));
}
function convertKubernetesMatchExpression(input: KubernetesMatchExpression[]) {
    return {
        InMatch: (input || [])
            .filter((m: KubernetesMatchExpression) => m.operator !== "Exists" && m.operator !== "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
            key: m.key,
            value: m.operator,
            option: (m.values ?? []).join(","),
        })),
        ExistMatch: (input || [])
            .filter((m: KubernetesMatchExpression) => m.operator === "Exists" || m.operator === "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
            key: m.key,
            value: m.operator,
        })),
    };
}
function getPreferredRules(input: string) {
    return JsonUtils.tryParseArray(input, []).filter((n: NodeAffinityDetails | PodAffinityDetails) => n.Type === "Preferred");
}
function getRequiredRules(input: string) {
    return JsonUtils.tryParseArray(input, []).filter((n: NodeAffinityDetails | PodAffinityDetails) => n.Type === "Required");
}
function importPersistentVolumeClaim(pvc: object) {
    return {
        Metadata: {
            Name: _.toString(_.get(pvc, "metadata.name")),
            LabelsRaw: _.get(pvc, "metadata.labels"),
            AnnotationsRaw: _.toPairs(_.get(pvc, "metadata.annotations") as {
                [key: string]: string;
            }).map((a: string[]) => ({
                key: _.toString(a[0]),
                value: _.toString(a[1]),
            })),
        },
        Spec: {
            VolumeName: _.toString(_.get(pvc, "spec.volumeName")),
            VolumeMode: _.toString(_.get(pvc, "spec.volumeMode")),
            AccessModes: (_.get(pvc, "spec.accessModes") || []).map((a: any) => _.toString(a)),
            StorageClassName: _.toString(_.get(pvc, "spec.storageClassName")),
            Selector: {
                MatchExpressions: (_.get(pvc, "spec.selector.matchExpressions") || []).map((m: any) => ({
                    key: _.toString(m.key),
                    value: _.toString(m.operator),
                    option: _.toString(m.values),
                })),
            },
            DataSource: {
                ApiGroup: _.toString(_.get(pvc, "spec.dataSource.apiGroup")),
                Name: _.toString(_.get(pvc, "spec.dataSource.name")),
                Kind: _.toString(_.get(pvc, "spec.dataSource.kind")),
            },
            Resources: {
                limits: {
                    storage: _.toString(_.get(pvc, "spec.resources.limits.storage")),
                },
                requests: {
                    storage: _.toString(_.get(pvc, "spec.resources.requests.storage")),
                },
            },
        },
    };
}
function exportPersistentVolumeClaim(pvc: PersistentVolumeClaimDetails) {
    return {
        kind: "PersistentVolumeClaim",
        apiVersion: "v1",
        metadata: {
            ...(pvc.Metadata?.LabelsRaw && {
                labels: pvc.Metadata?.LabelsRaw,
            }),
            ...(pvc.Metadata?.AnnotationsRaw && {
                annotations: _.fromPairs(pvc.Metadata?.AnnotationsRaw?.map((a: KeyValuePair) => [a.key, _.toString(a.value)])),
            }),
            ...(pvc.Metadata?.Name && {
                name: pvc.Metadata?.Name,
            }),
        },
        spec: {
            ...(pvc.Spec?.AccessModes && {
                accessModes: pvc.Spec?.AccessModes,
            }),
            ...((pvc.Spec?.DataSource?.ApiGroup || pvc.Spec?.DataSource?.Kind || pvc.Spec?.DataSource?.Name) && {
                dataSource: {
                    ...(pvc.Spec?.DataSource?.ApiGroup && {
                        apiGroup: pvc.Spec?.DataSource?.ApiGroup,
                    }),
                    ...(pvc.Spec?.DataSource?.Kind && {
                        kind: pvc.Spec?.DataSource?.Kind,
                    }),
                    ...(pvc.Spec?.DataSource?.Name && {
                        name: pvc.Spec?.DataSource?.Name,
                    }),
                },
            }),
            ...((pvc.Spec?.Resources?.limits?.storage || pvc.Spec?.Resources?.requests?.storage) && {
                resources: {
                    ...(pvc.Spec?.Resources?.limits?.storage && {
                        limits: {
                            storage: pvc.Spec?.Resources?.limits?.storage,
                        },
                    }),
                    ...(pvc.Spec?.Resources?.requests?.storage && {
                        requests: {
                            storage: pvc.Spec?.Resources?.requests?.storage,
                        },
                    }),
                },
            }),
            ...((pvc.Spec?.Selector?.MatchExpressions?.length || 0) !== 0 && {
                selector: {
                    matchExpressions: pvc.Spec?.Selector?.MatchExpressions?.map((m) => ({
                        key: m.key,
                        operator: m.value,
                        values: m.option.split("\n"),
                    })),
                },
            }),
            ...(pvc.Spec?.StorageClassName && {
                storageClassName: pvc.Spec?.StorageClassName,
            }),
            ...(pvc.Spec?.VolumeMode && {
                volumeMode: pvc.Spec?.VolumeMode,
            }),
            ...(pvc.Spec?.VolumeName && {
                volumeName: pvc.Spec?.VolumeName,
            }),
        },
    };
}
function importContainer(container: object, feeds: FeedResource[], init: boolean) {
    return {
        IsNew: true,
        TerminationMessagePath: _.get(container, "terminationMessagePath"),
        TerminationMessagePolicy: _.get(container, "terminationMessagePolicy"),
        InitContainer: init ? "True" : "False",
        ImagePullPolicy: _.get(container, "imagePullPolicy"),
        Ports: (_.get(container, "ports") ?? []).map((p: {
            name: string;
            containerPort: string | number;
            protocol: string;
        }) => ({
            ...(p.name && {
                key: _.toString(p.name),
            }),
            value: _.toString(p.containerPort),
            ...(p.protocol && {
                option: _.toString(p.protocol),
            }),
        })),
        EnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: {
            name: string;
            value: string;
        }) => p.value)
            .map((p: {
            name: string;
            value: string;
        }) => ({
            key: _.toString(p.name),
            value: _.toString(p.value),
        })),
        SecretEnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: object) => _.get(p, "valueFrom.secretKeyRef"))
            .map((p: KubernetesSecretEnvVar) => ({
            key: _.toString(p.name),
            value: _.toString(_.get(p, "valueFrom.secretKeyRef.name")),
            option: _.toString(_.get(p, "valueFrom.secretKeyRef.key")),
        })),
        SecretEnvFromSource: (_.get(container, "envFrom") ?? [])
            .filter((p: object) => _.get(p, "secretRef.name"))
            .map((p: KubernetesConfigmapEnvVar) => ({
            key: _.toString(_.get(p, "secretRef.name")),
            value: _.toString(_.get(p, "prefix")),
            option: _.toString(_.get(p, "secretRef.optional")),
        })),
        ConfigMapEnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: object) => _.get(p, "valueFrom.configMapKeyRef"))
            .map((p: KubernetesConfigmapEnvVar) => ({
            key: _.toString(p.name),
            value: _.toString(_.get(p, "valueFrom.configMapKeyRef.name")),
            option: _.toString(_.get(p, "valueFrom.configMapKeyRef.key")),
        })),
        ConfigMapEnvFromSource: (_.get(container, "envFrom") ?? [])
            .filter((p: object) => _.get(p, "configMapRef.name"))
            .map((p: KubernetesConfigmapEnvVar) => ({
            key: _.toString(_.get(p, "configMapRef.name")),
            value: _.toString(_.get(p, "prefix")),
            option: _.toString(_.get(p, "configMapRef.optional")),
        })),
        FieldRefEnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: object) => _.get(p, "valueFrom.fieldRef"))
            .map((p: {
            name: string;
            valueFrom: {
                fieldRef: {
                    fieldPath: string;
                };
            };
        }) => ({
            key: _.toString(p.name),
            value: _.toString(_.get(p, "valueFrom.fieldRef.fieldPath")),
        })),
        VolumeMounts: (_.get(container, "volumeMounts") ?? []).map((p: {
            name: string;
            mountPath: string;
            subPath: string;
        }) => ({
            key: _.toString(p.name),
            value: _.toString(p.mountPath),
            option: _.toString(p.subPath),
        })),
        AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
        Name: _.toString(_.get(container, "name")),
        PackageId: splitReposSearchTerm(_.get(container, "image"))[1],
        FeedId: getMatchingFeed(feeds, container).Id,
        Properties: {},
        Command: (_.get(container, "command") || []).map((c: string) => _.toString(c)),
        Args: (_.get(container, "args") || []).map((c: string) => _.toString(c)),
        Resources: {
            requests: {
                memory: _.toString(_.get(container, "resources.requests.memory")),
                cpu: _.toString(_.get(container, "resources.requests.cpu")),
                ephemeralStorage: _.toString(_.get(container, "resources.requests.ephemeralStorage")),
            },
            limits: {
                memory: _.toString(_.get(container, "resources.limits.memory")),
                cpu: _.toString(_.get(container, "resources.limits.cpu")),
                ephemeralStorage: _.toString(_.get(container, "resources.limits.ephemeralStorage")),
                nvidiaGpu: _.toString(_.get(container, ["resources", "limits", "nvidia.com/gpu"])),
                amdGpu: _.toString(_.get(container, ["resources", "limits", "amd.com/gpu"])),
            },
        },
        LivenessProbe: {
            failureThreshold: _.toString(_.get(container, "livenessProbe.failureThreshold")),
            initialDelaySeconds: _.toString(_.get(container, "livenessProbe.initialDelaySeconds")),
            periodSeconds: _.toString(_.get(container, "livenessProbe.periodSeconds")),
            successThreshold: _.toString(_.get(container, "livenessProbe.successThreshold")),
            timeoutSeconds: _.toString(_.get(container, "livenessProbe.timeoutSeconds")),
            type: _.get(container, "livenessProbe.exec.command") ? "Command" : _.get(container, "livenessProbe.httpGet.port") ? "HttpGet" : _.get(container, "livenessProbe.tcpSocket.port") ? "TcpSocket" : null,
            exec: {
                // we expect string array, but force the type just in case
                command: (_.get(container, "livenessProbe.exec.command") || []).map((c: string) => _.toString(c)),
            },
            httpGet: {
                host: _.toString(_.get(container, "livenessProbe.httpGet.host")),
                path: _.toString(_.get(container, "livenessProbe.httpGet.path")),
                port: _.toString(_.get(container, "livenessProbe.httpGet.port")),
                scheme: _.toString(_.get(container, "livenessProbe.httpGet.scheme")),
                httpHeaders: (_.get(container, "livenessProbe.httpGet.httpHeaders") ?? []).map((h: KubernetesHttpHeaders) => ({
                    key: _.toString(h.name),
                    value: _.toString(h.value),
                })),
            },
            tcpSocket: {
                host: _.toString(_.get(container, "livenessProbe.tcpSocket.host")),
                port: _.toString(_.get(container, "livenessProbe.tcpSocket.port")),
            },
        },
        ReadinessProbe: {
            failureThreshold: _.toString(_.get(container, "readinessProbe.failureThreshold")),
            initialDelaySeconds: _.toString(_.get(container, "readinessProbe.initialDelaySeconds")),
            periodSeconds: _.toString(_.get(container, "readinessProbe.periodSeconds")),
            successThreshold: _.toString(_.get(container, "readinessProbe.successThreshold")),
            timeoutSeconds: _.toString(_.get(container, "readinessProbe.timeoutSeconds")),
            type: _.get(container, "readinessProbe.exec.command") ? "Command" : _.get(container, "readinessProbe.httpGet.port") ? "HttpGet" : _.get(container, "readinessProbe.tcpSocket.port") ? "TcpSocket" : null,
            exec: {
                command: (_.get(container, "readinessProbe.exec.command") || []).map((c: string) => _.toString(c)),
            },
            httpGet: {
                host: _.toString(_.get(container, "readinessProbe.httpGet.host")),
                path: _.toString(_.get(container, "readinessProbe.httpGet.path")),
                port: _.toString(_.toString(_.get(container, "readinessProbe.httpGet.port"))),
                scheme: _.toString(_.get(container, "readinessProbe.httpGet.scheme")),
                httpHeaders: (_.get(container, "readinessProbe.httpGet.httpHeaders") ?? []).map((h: KubernetesHttpHeaders) => ({
                    key: _.toString(h.name),
                    value: _.toString(h.value),
                })),
            },
            tcpSocket: {
                host: _.toString(_.get(container, "readinessProbe.tcpSocket.host")),
                port: _.toString(_.get(container, "readinessProbe.tcpSocket.port")),
            },
        },
        StartupProbe: {
            failureThreshold: _.toString(_.get(container, "startupProbe.failureThreshold")),
            initialDelaySeconds: _.toString(_.get(container, "startupProbe.initialDelaySeconds")),
            periodSeconds: _.toString(_.get(container, "startupProbe.periodSeconds")),
            successThreshold: _.toString(_.get(container, "startupProbe.successThreshold")),
            timeoutSeconds: _.toString(_.get(container, "startupProbe.timeoutSeconds")),
            type: _.get(container, "startupProbe.exec.command") ? "Command" : _.get(container, "startupProbe.httpGet.port") ? "HttpGet" : _.get(container, "startupProbe.tcpSocket.port") ? "TcpSocket" : null,
            exec: {
                command: (_.get(container, "startupProbe.exec.command") || []).map((c: string) => _.toString(c)),
            },
            httpGet: {
                host: _.toString(_.get(container, "startupProbe.httpGet.host")),
                path: _.toString(_.get(container, "startupProbe.httpGet.path")),
                port: _.toString(_.toString(_.get(container, "startupProbe.httpGet.port"))),
                scheme: _.toString(_.get(container, "startupProbe.httpGet.scheme")),
                httpHeaders: (_.get(container, "startupProbe.httpGet.httpHeaders") ?? []).map((h: KubernetesHttpHeaders) => ({
                    key: _.toString(h.name),
                    value: _.toString(h.value),
                })),
            },
            tcpSocket: {
                host: _.toString(_.get(container, "startupProbe.tcpSocket.host")),
                port: _.toString(_.get(container, "startupProbe.tcpSocket.port")),
            },
        },
        Lifecycle: {
            ...(_.get(container, "lifecycle.preStop.exec.command") && {
                PreStop: {
                    Exec: {
                        command: (_.get(container, "lifecycle.preStop.exec.command") || []).map((c: string) => _.toString(c)),
                    },
                },
            }),
            ...(_.get(container, "lifecycle.postStart.exec.command") && {
                PostStart: {
                    Exec: {
                        command: (_.get(container, "lifecycle.postStart.exec.command") || []).map((c: string) => _.toString(c)),
                    },
                },
            }),
        },
        SecurityContext: {
            allowPrivilegeEscalation: _.toString(_.get(container, "securityContext.allowPrivilegeEscalation")),
            privileged: _.toString(_.get(container, "securityContext.privileged")),
            readOnlyRootFilesystem: _.toString(_.get(container, "securityContext.readOnlyRootFilesystem")),
            runAsGroup: _.toString(_.get(container, "securityContext.runAsGroup")),
            runAsNonRoot: _.toString(_.get(container, "securityContext.runAsNonRoot")),
            runAsUser: _.toString(_.get(container, "securityContext.runAsUser")),
            capabilities: {
                add: (_.get(container, "securityContext.capabilities.add") ?? []).map((v: string) => _.toString(v)),
                drop: (_.get(container, "securityContext.capabilities.drop") ?? []).map((v: string) => _.toString(v)),
            },
            seLinuxOptions: {
                level: _.toString(_.get(container, "securityContext.seLinuxOptions.level")),
                role: _.toString(_.get(container, "securityContext.seLinuxOptions.role")),
                type: _.toString(_.get(container, "securityContext.seLinuxOptions.type")),
                user: _.toString(_.get(container, "securityContext.seLinuxOptions.user")),
            },
        },
    };
}
function exportContainer(c: ContainerDetails, props: ActionEditProps<KubernetesDeploymentProperties, ScriptPackageProperties>, feeds: FeedResource[]) {
    return {
        name: c.Name,
        image: 
        /*
The image name in the export includes the hostname of the feed it is sourced from. By
embedding the fully qualified image name, the export can reference images from repos
other than docker hub.
*/
        getRegistryHostPrefix(feeds.find((f) => f.Id ==
            _.get(props.packages.find((p: ScriptPackageReference) => p.Name === c.Name), "FeedId"))) +
            _.get(props.packages.find((p: ScriptPackageReference) => p.Name === c.Name), "PackageId"),
        ...(c.ImagePullPolicy && {
            imagePullPolicy: c.ImagePullPolicy,
        }),
        ...(c.TerminationMessagePath && {
            terminationMessagePath: c.TerminationMessagePath,
        }),
        ...(c.TerminationMessagePolicy && {
            terminationMessagePolicy: c.TerminationMessagePolicy,
        }),
        ...(objectHasValuesOrNestedObjects(c.Command ?? []) && {
            command: c.Command,
        }),
        ...(objectHasValuesOrNestedObjects(c.Args ?? []) && {
            args: c.Args,
        }),
        ...((c.Ports ?? []).length !== 0 && {
            ports: (c.Ports ?? []).map((p: KeyValueOption) => ({
                ...(p.key && { name: p.key }),
                containerPort: getIntOrString(p.value),
                ...(p.option && { protocol: p.option }),
            })),
        }),
        ...((c.EnvironmentVariables ?? []).length + (c.SecretEnvironmentVariables ?? []).length + (c.ConfigMapEnvironmentVariables ?? []).length + (c.FieldRefEnvironmentVariables ?? []).length !== 0 && {
            env: _.concat((c.SecretEnvironmentVariables ?? []).map((p: KeyValueOption) => ({
                name: p.key,
                valueFrom: {
                    secretKeyRef: {
                        name: p.value,
                        key: p.option,
                    },
                },
            })) as object[], (c.ConfigMapEnvironmentVariables ?? []).map((p: KeyValueOption) => ({
                name: p.key,
                valueFrom: {
                    configMapKeyRef: {
                        name: p.value,
                        key: p.option,
                    },
                },
            })) as object[], (c.FieldRefEnvironmentVariables ?? []).map((p: KeyValueOption) => ({
                name: p.key,
                valueFrom: {
                    fieldRef: {
                        fieldPath: p.value,
                    },
                },
            })) as object[], (c.EnvironmentVariables ?? [])
                .filter((p: KeyValueOption) => p.value)
                .map((p: KeyValueOption) => ({
                name: p.key,
                value: p.value,
            })) as object[]),
        }),
        ...((c.ConfigMapEnvFromSource ?? []).length + (c.SecretEnvFromSource ?? []).length !== 0 && {
            envFrom: _.concat((c.SecretEnvFromSource ?? []).map((p: KeyValueOption) => ({
                ...(p.value && { prefix: p.value }),
                secretRef: {
                    name: p.key,
                    ...(p.option && { optional: p.option }),
                },
            })) as object[], (c.ConfigMapEnvFromSource ?? []).map((p: KeyValueOption) => ({
                ...(p.value && { prefix: p.value }),
                configMapRef: {
                    name: p.key,
                    ...(p.option && { optional: p.option }),
                },
            })) as object[]),
        }),
        ...(c.VolumeMounts &&
            c.VolumeMounts.length !== 0 && {
            volumeMounts: (c.VolumeMounts ?? []).map((p: KeyValueOption) => ({
                name: p.key,
                mountPath: p.value,
                subPath: p.option,
            })),
        }),
        ...(objectHasValuesOrNestedObjects(c.Resources) && {
            resources: {
                ...(objectHasValuesOrNestedObjects(_.get(c, "Resources.requests")) && {
                    requests: {
                        ...(_.get(c, "Resources.requests.memory") && {
                            memory: _.get(c, "Resources.requests.memory"),
                        }),
                        ...(_.get(c, "Resources.requests.cpu") && {
                            cpu: _.get(c, "Resources.requests.cpu"),
                        }),
                        ...(_.get(c, "Resources.requests.ephemeralStorage") && {
                            ephemeralStorage: _.get(c, "Resources.requests.ephemeralStorage"),
                        }),
                    },
                }),
                ...(objectHasValuesOrNestedObjects(_.get(c, "Resources.limits")) && {
                    limits: {
                        ...(_.get(c, "Resources.limits.memory") && {
                            memory: _.get(c, "Resources.limits.memory"),
                        }),
                        ...(_.get(c, "Resources.limits.cpu") && {
                            cpu: _.get(c, "Resources.limits.cpu"),
                        }),
                        ...(_.get(c, "Resources.limits.ephemeralStorage") && {
                            ephemeralStorage: _.get(c, "Resources.limits.ephemeralStorage"),
                        }),
                        ...(_.get(c, "Resources.limits.nvidiaGpu") && {
                            "nvidia.com/gpu": _.get(c, "Resources.limits.nvidiaGpu"),
                        }),
                        ...(_.get(c, "Resources.limits.amdGpu") && {
                            "amd.com/gpu": _.get(c, "Resources.limits.amdGpu"),
                        }),
                    },
                }),
            },
        }),
        ...(_.get(c, "LivenessProbe.type") && {
            livenessProbe: {
                ...(_.get(c, "LivenessProbe.failureThreshold") && {
                    failureThreshold: getIntOrString(_.get(c, "LivenessProbe.failureThreshold")),
                }),
                ...(_.get(c, "LivenessProbe.initialDelaySeconds") && {
                    initialDelaySeconds: getIntOrString(_.get(c, "LivenessProbe.initialDelaySeconds")),
                }),
                ...(_.get(c, "LivenessProbe.initialDelaySeconds") && {
                    periodSeconds: getIntOrString(_.get(c, "LivenessProbe.periodSeconds")),
                }),
                ...(_.get(c, "LivenessProbe.timeoutSeconds") && {
                    timeoutSeconds: getIntOrString(_.get(c, "LivenessProbe.timeoutSeconds")),
                }),
                ...(_.get(c, "LivenessProbe.type") === "Command" && {
                    exec: {
                        command: _.get(c, "LivenessProbe.exec.command"),
                    },
                }),
                ...(_.get(c, "LivenessProbe.type") === "HttpGet" && {
                    httpGet: {
                        host: _.get(c, "LivenessProbe.httpGet.host"),
                        path: _.get(c, "LivenessProbe.httpGet.path"),
                        port: getIntOrString(_.get(c, "LivenessProbe.httpGet.port")),
                        scheme: _.get(c, "LivenessProbe.httpGet.scheme"),
                        ...((_.get(c, "LivenessProbe.httpGet.httpHeaders") || []).length != 0 && {
                            httpHeaders: (_.get(c, "LivenessProbe.httpGet.httpHeaders") || []).map((h: KeyValueOption) => ({
                                name: h.key,
                                value: h.value,
                            })),
                        }),
                    },
                }),
                ...(_.get(c, "LivenessProbe.type") === "TcpSocket" && {
                    tcpSocket: {
                        host: _.get(c, "LivenessProbe.tcpSocket.host"),
                        port: getIntOrString(_.get(c, "LivenessProbe.tcpSocket.port")),
                    },
                }),
            },
        }),
        ...(_.get(c, "ReadinessProbe.type") && {
            readinessProbe: {
                ...(_.get(c, "ReadinessProbe.failureThreshold") && {
                    failureThreshold: getIntOrString(_.get(c, "ReadinessProbe.failureThreshold")),
                }),
                ...(_.get(c, "ReadinessProbe.initialDelaySeconds") && {
                    initialDelaySeconds: getIntOrString(_.get(c, "ReadinessProbe.initialDelaySeconds")),
                }),
                ...(_.get(c, "ReadinessProbe.periodSeconds") && {
                    periodSeconds: getIntOrString(_.get(c, "ReadinessProbe.periodSeconds")),
                }),
                ...(_.get(c, "ReadinessProbe.successThreshold") && {
                    successThreshold: getIntOrString(_.get(c, "ReadinessProbe.successThreshold")),
                }),
                ...(_.get(c, "ReadinessProbe.timeoutSeconds") && {
                    timeoutSeconds: getIntOrString(_.get(c, "ReadinessProbe.timeoutSeconds")),
                }),
                ...(_.get(c, "ReadinessProbe.type") === "Command" && {
                    exec: {
                        command: _.get(c, "ReadinessProbe.exec.command"),
                    },
                }),
                ...(_.get(c, "ReadinessProbe.type") === "HttpGet" && {
                    httpGet: {
                        host: _.get(c, "ReadinessProbe.httpGet.host"),
                        path: _.get(c, "ReadinessProbe.httpGet.path"),
                        port: getIntOrString(_.get(c, "ReadinessProbe.httpGet.port")),
                        scheme: _.get(c, "ReadinessProbe.httpGet.scheme"),
                        ...((_.get(c, "ReadinessProbe.httpGet.httpHeaders") || []).length != 0 && {
                            httpHeaders: (_.get(c, "ReadinessProbe.httpGet.httpHeaders") || []).map((h: KeyValueOption) => ({
                                name: h.key,
                                value: h.value,
                            })),
                        }),
                    },
                }),
                ...(_.get(c, "ReadinessProbe.type") === "TcpSocket" && {
                    tcpSocket: {
                        host: _.get(c, "ReadinessProbe.tcpSocket.host"),
                        port: getIntOrString(_.get(c, "ReadinessProbe.tcpSocket.port")),
                    },
                }),
            },
        }),
        ...(_.get(c, "StartupProbe.type") && {
            startupProbe: {
                ...(_.get(c, "StartupProbe.failureThreshold") && {
                    failureThreshold: getIntOrString(_.get(c, "StartupProbe.failureThreshold")),
                }),
                ...(_.get(c, "StartupProbe.initialDelaySeconds") && {
                    initialDelaySeconds: getIntOrString(_.get(c, "StartupProbe.initialDelaySeconds")),
                }),
                ...(_.get(c, "StartupProbe.periodSeconds") && {
                    periodSeconds: getIntOrString(_.get(c, "StartupProbe.periodSeconds")),
                }),
                ...(_.get(c, "StartupProbe.successThreshold") && {
                    successThreshold: getIntOrString(_.get(c, "StartupProbe.successThreshold")),
                }),
                ...(_.get(c, "StartupProbe.timeoutSeconds") && {
                    timeoutSeconds: getIntOrString(_.get(c, "StartupProbe.timeoutSeconds")),
                }),
                ...(_.get(c, "StartupProbe.type") === "Command" && {
                    exec: {
                        command: _.get(c, "StartupProbe.exec.command"),
                    },
                }),
                ...(_.get(c, "StartupProbe.type") === "HttpGet" && {
                    httpGet: {
                        host: _.get(c, "StartupProbe.httpGet.host"),
                        path: _.get(c, "StartupProbe.httpGet.path"),
                        port: getIntOrString(_.get(c, "StartupProbe.httpGet.port")),
                        scheme: _.get(c, "StartupProbe.httpGet.scheme"),
                        ...((_.get(c, "StartupProbe.httpGet.httpHeaders") || []).length != 0 && {
                            httpHeaders: (_.get(c, "StartupProbe.httpGet.httpHeaders") || []).map((h: KeyValueOption) => ({
                                name: h.key,
                                value: h.value,
                            })),
                        }),
                    },
                }),
                ...(_.get(c, "StartupProbe.type") === "TcpSocket" && {
                    tcpSocket: {
                        host: _.get(c, "StartupProbe.tcpSocket.host"),
                        port: getIntOrString(_.get(c, "StartupProbe.tcpSocket.port")),
                    },
                }),
            },
        }),
        ...(objectHasValuesOrNestedObjects(c.Lifecycle) && {
            lifecycle: {
                ...(_.get(c, "Lifecycle.PreStop") && {
                    preStop: {
                        exec: {
                            command: _.get(c, "Lifecycle.PreStop.Exec.command"),
                        },
                    },
                }),
                ...(_.get(c, "Lifecycle.PostStart") && {
                    postStart: {
                        exec: {
                            command: _.get(c, "Lifecycle.PostStart.Exec.command"),
                        },
                    },
                }),
            },
        }),
        ...(objectHasValuesOrNestedObjects(c.SecurityContext) && {
            securityContext: {
                ...(_.get(c, "SecurityContext.allowPrivilegeEscalation") && {
                    allowPrivilegeEscalation: getBoolOrString(_.get(c, "SecurityContext.allowPrivilegeEscalation")),
                }),
                ...(_.get(c, "SecurityContext.privileged") && {
                    privileged: getBoolOrString(_.get(c, "SecurityContext.privileged")),
                }),
                ...(_.get(c, "SecurityContext.runAsGroup") && {
                    readOnlyRootFilesystem: getBoolOrString(_.get(c, "SecurityContext.readOnlyRootFilesystem")),
                }),
                runAsGroup: getIntOrString(_.get(c, "SecurityContext.runAsGroup")),
                ...(_.get(c, "SecurityContext.runAsNonRoot") && {
                    runAsNonRoot: getBoolOrString(_.get(c, "SecurityContext.runAsNonRoot")),
                }),
                ...(_.get(c, "SecurityContext.runAsUser") && {
                    runAsUser: getIntOrString(_.get(c, "SecurityContext.runAsUser")),
                }),
                ...(_.get(c, "SecurityContext.supplementalGroups") && {
                    supplementalGroups: getIntOrString(_.get(c, "SecurityContext.supplementalGroups")),
                }),
                ...(objectHasValuesOrNestedObjects(_.get(c, "SecurityContext.capabilities")) && {
                    capabilities: {
                        add: _.get(c, "SecurityContext.capabilities.add"),
                        drop: _.get(c, "SecurityContext.capabilities.drop"),
                    },
                }),
                ...(objectHasValuesOrNestedObjects(_.get(c, "SecurityContext.seLinuxOptions")) && {
                    seLinuxOptions: {
                        level: _.get(c, "SecurityContext.seLinuxOptions.level"),
                        role: _.get(c, "SecurityContext.seLinuxOptions.role"),
                        type: _.get(c, "SecurityContext.seLinuxOptions.type"),
                        user: _.get(c, "SecurityContext.seLinuxOptions.user"),
                    },
                }),
            },
        }),
    };
}
function exportHostAlias(hostAlias: KeyValueOption) {
    const hostnames = hostAlias.value.split(",").map((h) => h.trim());
    return {
        ip: hostAlias.key,
        hostnames: hostnames,
    };
}
/**
 * Returns true if myObject contains any significant non-object parameters. Any object parameters are recursively tested
 * with this function.
 * @param myObject The object to test
 * @return true if the object to tests contains useful values, and false otherwise
 */
function objectHasValuesOrNestedObjects(myObject: object): boolean {
    return _.values(myObject ?? {}).some((v: any) => {
        return (
        // most properties with a useful value are considered significant
        // Only non empty objects are significant
        !_.isUndefined(v) &&
            !_.isNull(v) &&
            !_.isNaN(v) &&
            !_.isSymbol(v) &&
            // Only non empty strings are significant
            (!_.isString(v) || v.length !== 0) &&
            // Only non empty arrays are significant
            (!_.isArray(v) || v.length !== 0) &&
            (!_.isObject(v) || objectHasValuesOrNestedObjects(v)));
    });
}
function isRecord(value: unknown): value is Record<string, any> {
    return value !== null && typeof value === "object";
}
export function isError(value: unknown): value is Error {
    return (value as Error).message !== undefined;
}
function loadKubernetesYamlAsObject(yamlString: string): object {
    const yaml = jsyaml(yamlString);
    return typeof yaml === "object" ? yaml ?? {} : {};
}
