/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { NavigationBar, Divider, Tooltip } from "@octopusdeploy/design-system-components";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import { values } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { useDispatchNotificationCallToActionClicked } from "~/components/Navbar/analytics/useDispatchNotificationCallToActionClicked";
import { useDispatchNotificationsIconClicked } from "~/components/Navbar/analytics/useDispatchNotificationsIconClicked";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import ExternalLink from "../Navigation/ExternalLink";
import InternalLink from "../Navigation/InternalLink";
import * as notificationLocalState from "./NotificationPoller/notificationLocalState";
import styles from "./notifications.module.less";
import type { NotificationItem, NotificationItemLink, NotificationItemExternalLink, NotificationItemInternalLink } from "./notifications.redux";
import { NotificationType } from "./notifications.redux";
interface NotificationsMenuState {
    isMenuOpen: boolean;
    notifications: NotificationItem[];
    showNewNotificationsAlert: boolean;
}
interface NotificationsMenuInternalProps {
    notifications: NotificationItem[];
    onNotificationOpened: (options: {
        hasActiveAlert: boolean;
    }) => void;
    onNotificationCallToActionClicked: (notification: NotificationItem) => void;
}
const defaultNotification: NotificationItem = {
    id: null!,
    text: "You have no notifications.",
    type: NotificationType.happy,
    description: "Happy deployments!",
};
class NotificationsMenuInternal extends BaseComponent<NotificationsMenuInternalProps, NotificationsMenuState> {
    private menuButton: HTMLElement | null = null;
    constructor(props: NotificationsMenuInternalProps) {
        super(props);
        this.state = {
            isMenuOpen: false,
            notifications: [defaultNotification],
            showNewNotificationsAlert: false,
        };
    }
    async componentDidMount() {
        this.checkNotificationsAlert(this.props);
    }
    UNSAFE_componentWillReceiveProps(newProps: NotificationsMenuInternalProps) {
        if (this.props.notifications !== newProps.notifications) {
            this.checkNotificationsAlert(newProps);
        }
    }
    checkNotificationsAlert = (props: NotificationsMenuInternalProps) => {
        const showNewNotificationsAlert = !!props.notifications.find((n) => {
            const currentValue = notificationLocalState.getCurrentValue(n.id);
            const notificationHasValue = !!n.value;
            return !currentValue || !((notificationHasValue && currentValue === n.value) || !notificationHasValue);
        });
        this.setState({ showNewNotificationsAlert });
    };
    renderNotification = (notification: NotificationItem, index: number) => {
        return (<div key={notification.text} className={styles.container}>
                {index > 0 && <Divider />}
                <div className={styles.notification}>
                    <div className={styles.grow}>
                        <div className={cn(styles.title, {
                [styles.textHappy]: notification.type === NotificationType.happy,
                [styles.textInfo]: notification.type === NotificationType.info,
                [styles.textWarn]: notification.type === NotificationType.warn,
                [styles.textError]: notification.type === NotificationType.error,
            })}>
                            {notification.text}
                        </div>
                        <div className={styles.description}>{notification.description}</div>
                        {notification.links && (<div className={styles.links}>
                                {notification.links.map((link, i) => (<span key={i}>
                                        {i > 0 && " | "}
                                        {this.renderLink(notification, link)}
                                    </span>))}
                            </div>)}
                    </div>
                    <div className={styles.icon}>{this.displayIcon(notification.type)}</div>
                </div>
            </div>);
    };
    renderLink(notification: NotificationItem, link: NotificationItemLink | NotificationItemInternalLink | NotificationItemExternalLink) {
        switch (link.type) {
            case "External": {
                return (<ExternalLink href={link.href} onClick={() => this.props.onNotificationCallToActionClicked(notification)}>
                        {link.label}
                    </ExternalLink>);
            }
            case "Internal": {
                return (<InternalLink to={link.href} onClick={() => this.props.onNotificationCallToActionClicked(notification)}>
                        {link.label}
                    </InternalLink>);
            }
            case "Refresh": {
                return (<a onClick={() => {
                        this.props.onNotificationCallToActionClicked(notification);
                        window.location.reload();
                    }} href="#">
                        {link.label}
                    </a>);
            }
            default: {
                return <span>{link.label}</span>;
            }
        }
    }
    render() {
        const notifications = [...this.props.notifications];
        if (notifications.length === 0) {
            notifications.push(defaultNotification);
        }
        return (<div>
                <NotificationButton onClick={this.handleTouchTap} accessibleName="Notifications" hasNewNotifications={this.state.showNewNotificationsAlert} isOpen={this.state.isMenuOpen} ref={this.setRef}/>
                <Popover open={this.state.isMenuOpen} anchorEl={this.menuButton} onClose={this.handleRequestClose} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                    {notifications.map(this.renderNotification)}
                </Popover>
            </div>);
    }
    private markNotificationsAsRead = () => {
        this.props.notifications.forEach((n) => notificationLocalState.setCurrentValue(n.id, n.value));
        this.setState({
            showNewNotificationsAlert: false,
        });
    };
    private displayIcon = (type: NotificationType) => {
        switch (type) {
            case NotificationType.happy:
                return <div className={cn("fa-regular fa-smile", styles.notificationIcon, styles.bgHappy)}/>;
            case NotificationType.info:
                return <div className={cn("fa-solid fa-circle-info", styles.notificationIcon, styles.bgInfo)}/>;
            case NotificationType.warn:
                return <div className={cn("fa-solid fa-triangle-exclamation", styles.notificationIcon, styles.bgWarn)}/>;
            case NotificationType.error:
                return <div className={cn("fa-solid fa-triangle-exclamation", styles.notificationIcon, styles.bgError)}/>;
        }
    };
    private setRef = (el: HTMLElement | null) => {
        this.menuButton = el;
    };
    private handleTouchTap = (event: React.MouseEvent) => {
        event.preventDefault();
        const hasActiveAlert = this.state.showNewNotificationsAlert;
        this.markNotificationsAsRead();
        this.setState({
            isMenuOpen: !this.state.isMenuOpen,
        });
        this.props.onNotificationOpened({ hasActiveAlert });
    };
    private handleRequestClose = () => {
        this.setState({
            isMenuOpen: false,
        });
    };
    static displayName = "NotificationsMenuInternal";
}
interface NotificationButtonUpliftProps {
    onClick: React.MouseEventHandler;
    accessibleName: string;
    hasNewNotifications: boolean;
    isOpen: boolean;
}
const NotificationButton = React.forwardRef<HTMLButtonElement, NotificationButtonUpliftProps>(({ onClick, accessibleName, hasNewNotifications, isOpen }, ref) => {
    return (<Tooltip content="Notifications">
            <NavigationBar.IconButton onClick={onClick} accessibleName={accessibleName} aria-expanded={isOpen} ref={ref} icon={<>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3625 2.99999C11.3625 2.69999 11.6625 2.39999 11.9625 2.39999C12.3 2.39999 12.6 2.69999 12.6 2.99999V3.63749C15.6 3.93749 18 6.48749 18 9.59999V10.725C18 12.3375 18.6375 13.9125 19.8 15.075L19.9125 15.1875C20.2125 15.4875 20.4 15.9 20.4 16.35C20.4 17.2875 19.65 18 18.7125 18H5.24998C4.31248 18 3.59998 17.2875 3.59998 16.35C3.59998 15.9 3.74998 15.4875 4.04998 15.1875L4.16248 15.075C5.32498 13.9125 5.99998 12.3375 5.99998 10.725V9.59999C5.99998 6.48749 8.36248 3.93749 11.4 3.63749L11.3625 2.99999ZM11.9625 4.79999C9.33748 4.79999 7.16248 6.97499 7.16248 9.59999V10.725C7.16248 12.675 6.41248 14.55 5.02498 15.9375L4.91248 16.0125C4.83748 16.125 4.76248 16.2375 4.76248 16.35C4.76248 16.6125 4.98748 16.8 5.24998 16.8H18.7125C18.975 16.8 19.2 16.6125 19.2 16.35C19.2 16.2375 19.125 16.125 19.05 16.0125L18.9375 15.9375C17.55 14.55 16.8 12.675 16.8 10.725V9.59999C16.8 6.97499 14.625 4.79999 11.9625 4.79999ZM11.9625 20.4C12.4875 20.4 12.9375 20.1 13.125 19.6125C13.2375 19.3125 13.575 19.125 13.875 19.2375C14.175 19.35 14.3625 19.6875 14.25 20.025C13.9125 20.9625 13.0125 21.6 11.9625 21.6C10.95 21.6 10.05 20.9625 9.71248 20.025C9.59998 19.6875 9.78748 19.35 10.0875 19.2375C10.3875 19.125 10.725 19.3125 10.8375 19.6125C11.025 20.1 11.475 20.4 11.9625 20.4Z"/>
                        </svg>
                        {hasNewNotifications && <div className={newNotificationsDotStyles}/>}
                    </>}/>
        </Tooltip>);
});
const newNotificationsDotStyles = css({
    position: "absolute",
    top: 4,
    right: 4,
    height: 14,
    width: 14,
    borderRadius: 10,
    background: colorScales.red[500],
});
export function NotificationsMenu() {
    const notifications = useSelector((state: GlobalState) => values(state.notifications));
    const notifyNotificaitonIconClicked = useDispatchNotificationsIconClicked();
    const notifyCallToActionClicked = useDispatchNotificationCallToActionClicked();
    const onNotificationCallToActionClicked = React.useCallback((item: NotificationItem) => {
        notifyCallToActionClicked({ notificationId: item.id });
    }, [notifyCallToActionClicked]);
    return <NotificationsMenuInternal notifications={notifications} onNotificationOpened={notifyNotificaitonIconClicked} onNotificationCallToActionClicked={onNotificationCallToActionClicked}/>;
}
export default NotificationsMenu;
