export const projectInsightsLinks = (root: string) => {
    return {
        root,
        overview: `${root}`,
        leadTime: `${root}/lead-time`,
        frequency: `${root}/frequency`,
        failureRate: `${root}/failure-rate`,
        timeToRecovery: `${root}/mttr`,
        deploymentDuration: `${root}/deployment-duration`,
    };
};
