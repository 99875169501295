import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { colorScales, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import * as React from "react";
import { Action, useAnalyticConnectTenantWizardDialogDispatch } from "~/analytics/Analytics";
import type { SelectItemType } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import DialogLayoutConnect from "~/components/Dialog/DialogLayoutConnect";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { CustomDialogActions, CustomFlexDialogContent } from "~/components/DialogLayout/Custom/index";
import type { DialogLayoutCommonProps, DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import KeyboardHandler, { Key } from "~/components/KeyboardHandler/index";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
interface ConnectionWizardDialogLayoutProps extends DialogLayoutCommonProps {
    saveButtonDisabled?: boolean;
    savePermission?: PermissionCheckProps;
    saveButtonLabel: string;
    onSaveClick(): Promise<boolean>;
    wizardStepNames: Array<string>;
    selectItemType: SelectItemType;
}
function ConnectionWizardDialogLayoutInternal({ onSaveClick, close, saveButtonDisabled, busy, children, saveButtonLabel, title, wizardStepNames, savePermission, selectItemType, errors, }: PropsWithChildren<ConnectionWizardDialogLayoutProps & DialogLayoutDispatchProps>) {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [dialogResizeKey] = useState(new Date().getDate().toString());
    const trackAction = useAnalyticConnectTenantWizardDialogDispatch();
    const saveClick = async () => {
        const result = await onSaveClick();
        if (result) {
            close();
        }
    };
    const isSaveButtonDisabled = (): boolean => {
        const disabledDueToPermission = !!savePermission ? !isAllowed(savePermission) : false;
        return !!(saveButtonDisabled || disabledDueToPermission || busy);
    };
    const canPrevious = () => currentPageIndex > 0;
    const previous = () => {
        if (canPrevious()) {
            trackAction("Navigate to Previous Step", { resource: selectItemType, action: Action.View, currentStepName: wizardStepNames[currentPageIndex] });
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };
    const canNext = () => currentPageIndex < React.Children.count(children) - 1;
    const next = () => {
        if (canNext()) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };
    const onEscKeyPressed = () => {
        close();
        return true;
    };
    const onCancel = () => {
        trackAction(`Cancel Connecting ${selectItemType}s`, { resource: selectItemType, action: Action.Cancel, currentStepName: wizardStepNames[currentPageIndex] });
        close();
    };
    const showSaveButton = React.Children.toArray(children).length - 1 === currentPageIndex;
    const saveActionButton = showSaveButton ? <ActionButton key="Save" type={ActionButtonType.Save} label={saveButtonLabel} busyLabel={"Connecting..."} onClick={saveClick} disabled={isSaveButtonDisabled()}/> : <></>;
    const cancelActionButton = <ActionButton key="Cancel" label="Cancel" disabled={busy} onClick={onCancel} type={ActionButtonType.Ternary}/>;
    let actions: JSX.Element[] = [];
    const previousActionButton = <ActionButton key="Previous" label="Previous" disabled={busy || !canPrevious()} onClick={previous} type={ActionButtonType.Secondary}/>;
    const nextActionButton = <ActionButton key="Next" label="Next" disabled={busy || !canNext()} onClick={next} type={ActionButtonType.Primary}/>;
    if (currentPageIndex === 0) {
        actions = [cancelActionButton, previousActionButton, nextActionButton];
    }
    else {
        actions = [cancelActionButton, previousActionButton, saveActionButton];
    }
    const keyboardRegistrations = [{ key: Key.Esc, onKeyPressed: onEscKeyPressed }];
    const child = React.Children.toArray(children)[currentPageIndex];
    return (<CustomSaveDialogLayout open={true} // This is set to true as it is required as a prop for this component. Although it is not used explicitly in it, it is passed as parameters to the render methods, but it is not needed in our case.
     close={close} onSaveClick={onSaveClick} renderTitle={() => <ConnectionWizardDialogTitleBar title={title} pageNames={wizardStepNames} currentPageIndex={currentPageIndex}/>} renderContent={() => (<CustomFlexDialogContent>
                    <KeyboardHandler key={dialogResizeKey} registrations={keyboardRegistrations}>
                        {child}
                    </KeyboardHandler>
                </CustomFlexDialogContent>)} renderActions={() => <CustomDialogActions actions={actions}/>} busy={busy ? busy : undefined} errors={errors ? errors : undefined}></CustomSaveDialogLayout>);
}
interface ConnectTenantWizardDialogTitleBarProps {
    title: string | React.ReactNode;
    pageNames: Array<string>;
    currentPageIndex: number;
}
const ConnectionWizardDialogTitleBar = ({ title, pageNames, currentPageIndex }: ConnectTenantWizardDialogTitleBarProps) => (<div className={styles.titleBar}>
        <div className={styles.title}>{title}</div>
        <div className={styles.wizardStepBar}>
            <ul>
                {pageNames.map((pageName, index) => (<li key={pageName} className={index === currentPageIndex ? styles.currentWizardStepIndicator : styles.otherWizardStepIndicator}>
                        <span className={cn(styles.wizardStepNumberIconBase, index === currentPageIndex ? styles.currentWizardStepNumberIcon : styles.otherWizardStepNumberIcon)}>{index + 1}</span>
                        {pageName}
                    </li>))}
            </ul>
        </div>
    </div>);
const styles = {
    currentWizardStepIndicator: css({
        color: themeTokens.color.text.selected,
        display: "flex",
        gap: "0.25rem",
    }),
    otherWizardStepIndicator: css({
        color: themeTokens.color.text.subtle,
        display: "flex",
        gap: "0.25rem",
    }),
    wizardStepBar: css({
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        ul: {
            listStyleType: "none",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "16px",
            margin: 0,
        },
        li: {
            paddingRight: "16px",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            "&:not(:last-child)": {
                width: "100%",
            },
            "&:not(:last-child):after": {
                content: "\"\"",
                borderBottom: `1px solid ${themeTokens.color.text.subtle}`,
                transform: "translateY(-50%)",
                width: "32px",
                marginLeft: "16px",
            },
        },
    }),
    titleBar: css({
        backgroundColor: themeTokens.color.background.primary.default,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: "0 0 auto",
        padding: "20px 16px",
    }),
    title: css({
        flexGrow: 1,
        fontSize: "16px",
        fontWeight: 500,
        font: text.interface.heading.small,
        margin: 0,
        padding: 0,
    }),
    otherWizardStepNumberIcon: css({
        backgroundColor: themeTokens.color.text.subtle,
    }),
    currentWizardStepNumberIcon: css({
        background: themeTokens.color.text.selected,
    }),
    wizardStepNumberIconBase: css({
        borderRadius: "14px",
        margin: "3px",
        display: "flex",
        width: "28px",
        height: "28px",
        justifyContent: "center",
        alignItems: "center",
        color: colorScales.white,
    }),
};
const ConnectionWizardDialogLayout = DialogLayoutConnect.to<ConnectionWizardDialogLayoutProps>(ConnectionWizardDialogLayoutInternal);
ConnectionWizardDialogLayout.displayName = "ConnectTenantWizardDialogLayout";
export default ConnectionWizardDialogLayout;
